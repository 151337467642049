import TableFunctions from '../datatables/api/TableFunctions.js'
import ReactTable from "react-table-6"
import Translation from '../user/Translation'
import GetJsonFormPHP, { PostJsonFormPHP } from "../datatables/api/GetJsonFormPHP";
import UserProfile from '../user/UserProfile'
import Barcode from 'react-barcode';
import '../datatables/styles/react-table.css'
import imgEdit from '../datatables/img/editdetail.png'

function t(field) { return Translation.get(field) }

class Customer extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/users/get_trader.php",
      saveCommentUrl: "/users/set_comment.php",
      contact: null,
      data: [], empty: null, selected: -1,
      title: "", comment: "", comment_type: 1, editable: true,
    }
    this.saveComment = this.saveComment.bind(this)
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      if (result.data === null) result.data = [{id : -1}]
      this.setState({
        contact: result.contact,
        data: result.data,
      })
    })
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    return [
      { accessor: 'id', show: false,},
      { accessor: 'type', show: false,},
      { accessor: 'new', show: false,},
      this.addTextCell('contact_title', 'title', 0, 0, true, "", null, null, null, false),
      this.addTextCell('contact_content', 'content', 0, 0, true, "", null, null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 10.5*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          return (
            <div><span><img src={imgEdit} alt={t("*edit")} title={t("*edit")} onClick={() => { this.editEntry(row) }}/></span></div>
          )
        },
      }
    ]
  }
  editEntry = (row) =>{
    document.getElementById('user_comment').innerHTML = row.content
    this.setState({ title: row.title, selected: row.id, comment_type: row.type, editable: row.new === 1 ? true : false })
  }
  saveComment(event) {
    event.preventDefault();
    let data = {}
    data.title = this.state.title
    data.content = this.state.comment
    data.id = this.state.selected
    data.type = this.state.comment_type
    PostJsonFormPHP(this.state.saveCommentUrl, data).then((result) => { this.componentDidMount() })
  }

  render() {
    //{t("contact")}<br />
    const columns = this.createColumns()
    let  content = ""
    if (document.getElementById('user_comment')) content = document.getElementById('user_comment').innerHTML
    const setContent = value => {
      const MAXLENGTH = 500000
      let divText = document.getElementById('user_comment')
      const  content = divText.innerHTML
      if (content.length < MAXLENGTH) this.setState({ comment: content, })
      else {
        this.setState({ comment: content.substring(0, MAXLENGTH) })
        document.getElementById('user_comment').innerHTML = content.substring(0, MAXLENGTH)
      }
      console.log(content.length, MAXLENGTH)
    }
    const clearComment = value => {
      this.setState({ comment: "", selected: -1, comment_type: 1, editable: true, })
    }
    //const typeChange = value => { this.handleInputChange(row._index, id, value) }
    //<input type="file" id="imageInput" accept="image/*" /><br />
    //console.log(this.state.comment_type, this.state.editable)

    //let print_content = document.getElementById("divcontents");
    //let pri = document.getElementById("ifmcontentstoprint").contentWindow;
    //pri.document.open();
    //pri.document.write(print_content.innerHTML);
    //pri.document.close();
    //pri.focus();
    const printWindow = value => {
      //let pri = document.getElementById("ifmcontentstoprint").contentWindow;
      let print_content = document.getElementById("divcontents");
      let print_window = window.open('', '_blank');
      print_window.document.open();
      print_window.document.write("<html><head><style>@media print { @page { margin: 0.1in; } body { width: 2.7in; max-height: 1.25in; padding: 8px; border: 1px solid; background: rgba(255, 255, 255, 1); } table {font-weight: bold; font-size: 0.8em;} .img_top { width: 1.30in; } .img_ce { width: 0.6in; } svg {max-width: 2.3in; margin: 0px; padding:0px;}} </style></head><body>" + print_content.innerHTML + "</body></html>");
      print_window.document.close();
      print_window.print();
    }
    /*const printFrame = value => {
      //let pri = document.getElementById("ifmcontentstoprint").contentWindow;
      let print_content = document.getElementById("divcontents");
      let pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(print_content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
    }*/
    return (
      <div align="center">
        {this.state.contact ? <div>
          <h2>{this.state.contact.company_name}</h2>
          <div dangerouslySetInnerHTML={{__html: this.state.contact.company_address}} /><br /><br />
          <div dangerouslySetInnerHTML={{__html: this.state.contact.contact}} />
          {this.state.contact.phone}<br />
          <div dangerouslySetInnerHTML={{__html: this.state.contact.support}} /><br /><br />
          {UserProfile.checkSupport() ?
          <form>
            <div>
              <label className="checkboxlabel"><input type="radio" name="type1" value="1" className="checkbox" checked={parseInt(this.state.comment_type) === 1} onChange={() => {this.setState({comment_type: 1,})}} />{t("contact_suggestion")}</label><br />
              <label className="checkboxlabel"><input type="radio" name="type2" value="2" className="checkbox" checked={parseInt(this.state.comment_type) === 2} onChange={() => {this.setState({comment_type: 2,})}} />{t("contact_bug")}</label><br />
            </div>
            <input type="text" name="title" value={this.state.title} onChange={(value) => { this.setState({ title: value.target.value})}} /><br /><br />
            <div id="user_comment" onBlur={setContent} onKeyDown={setContent} contentEditable style={{border: "2px solid green", backgroundColor: "#FFFFFF", padding: "4px", minHeight: "120px", width: "100%"}}></div><br />
            <button onClick={this.saveComment} disabled={!this.state.editable && this.state.selected !== -1 && content.length > 1}>{t("save")}</button>
            {content.length > 1 ? <button onClick={clearComment}>{t("clear")}</button> : null}
          </form> : null}
        </div> : null}
        {UserProfile.checkSupport() ?
        <div style={{fontSize: this.state.fontSize+"em"}}>
          {this.state.data ?
          <ReactTable
            id="react-table"
            data={this.state.data}
            columns={columns}
            minRows = {0}
            previousText= {'<'}
            nextText= {'>'}
            showPageJump= {true}
            defaultPageSize = {25}
            pageSizeOptions = {[10, 25, 50, 100]}
            useFlexLayout = {true}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                  style: { fontSize: null }
              }
            }}
          /> : null }  <br />
        </div> : null }
        {UserProfile.getUserLevel() <= 2 ?
        <div id="divcontents" className="etikett_print">
          <table width="100%" className="etikett_table" border="0" cellpadding="0" cellspacing="0">
            <tr><td width="10%">Typ:</td><td width="65%">Aranom Roboter</td><td rowspan="2" colspan="2" valign="top" align="right"><img src="https://connect.hetwin.at/public/img/Hetwin_Logo.png" alt="" className="img_top" width="250px" /></td></tr>
            <tr><td>BJ:</td><td>06.2024</td></tr>
            <tr><td>VPN:</td><td> &nbsp; 10.100.2.10</td><td width="20%">&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td valign="center">SN: </td><td colspan="3"><Barcode value="HQ2322UXYDQ" format="CODE128" width="1" height="25" marginTop="3" marginBottom="0" fontSize="15" /></td></tr>
            <tr><td colspan="3">Anschlussleistung: 50kW</td><td align="right" valign="top"><img src="https://connect.hetwin.at/public/img/CE.png" alt="" className="img_ce" width="90px" /></td></tr>
          </table>
          <button onClick={printWindow}>{t("print")}</button> <br />
        </div> : null }

      </div>
    )
  }
  //           <iframe id="ifmcontentstoprint" className="print_window" title="printFrame"><Barcode value="barcode-example" format="CODE128" height="70" /></iframe>

}

export default Customer;
/* Barcode options
https://github.com/lindell/JsBarcode/wiki/Options

<Barcode value="barcode-example"  format="CODE128" height="70" />

*/