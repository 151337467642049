import React, { Component } from 'react';
//import { Route, Routes, Link } from 'react-router-dom';
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP.js";
//import UserProfile from '../user/UserProfile.js'

import Translation from '../user/Translation';
//import UserList from '../datatables/UserList.js';
//import Customers from '../datatables/CustomerList.js';

function t(field) { return Translation.get(field) }

class Customer extends Component {
  constructor() {
    super();
    this.state={
      geturl: "/users/get_customer_data.php",
      customer: null,
      robot: null,
      robotId: "0",
      ID: "0",
    }
  }
  componentDidMount(id = this.state.robotId) {
    //if (UserProfile.getServiceReportID())
    if (id !== "0") {
      this.setState({ ID: id, })
      //console.log('fetch data')
      GetJsonFormPHP(this.state.geturl + "?robot_id=" + id).then((result) => {
        this.setState({
          customer: result.customer,
          robot: result.robot,
        })
      })
    }
  }
  static getDerivedStateFromProps(props, state) {
    //console.log(props)
    if (state.robotId !== props.id) {
      return {
        robotId: props.id,
      }
    }
    return null
  }

  render() {
    const customer = this.state.customer
    const robot = this.state.robot
    //if ((customer && this.state.customerId !== customer.id) || (this.state.robotId && !customer)) this.componentDidMount()
    if (this.state.robotId !== this.state.ID) this.componentDidMount(this.state.robotId)
    //console.log("main", this.state.robotId)
  console.log(robot)
    return (
      <div className="body">
        {customer !== null ?
        <div className ="home_dashboard_main">
          <div className="home_dashboard">
            <table width="100%" cellSpacing="0"  cellPadding="0" border="0" className="customerhead"><tbody>
              <tr> <td><b>{t("customer")}</b></td></tr>
            </tbody></table>
            <table width="100%" cellSpacing="1"  cellPadding="0" border="0" className="customerinfo"><tbody>
              <tr><th valign="top">{t('customer')}</th><td>{customer.company_name}</td></tr>
              <tr><th valign="top">{t('address')}</th><td><span dangerouslySetInnerHTML={{__html: customer.company_address.replaceAll("\n", "<br/>")}}></span></td></tr>
              <tr><th valign="top">{t('contact')}</th><td><span dangerouslySetInnerHTML={{__html: customer.contact.replaceAll("\n", "<br/>")}}></span></td></tr>
              <tr><th valign="top">{t('email')}</th><td>{customer.email}</td></tr>
              <tr><th valign="top">{t('telephone')}</th><td>{customer.phone}</td></tr>
              <tr><th valign="top">{t('customer_info')}</th><td>{customer.info}</td></tr>
              <tr><th valign="top">{t('enabled')}</th><td>{customer.enabled === 1 ? t("enabled") : t("disabled") }</td></tr>
            </tbody></table>
          </div>
        </div>
        : null}
        {robot !== null ? robot.map(((t1,k) => { console.log(t1,k); return <div className ="home_dashboard_main"><div className="home_dashboard">
          <table width="100%" cellSpacing="0"  cellPadding="0" border="0" className="customerhead"><tbody>
            <tr><td><b>{t1.name}</b></td> </tr>
          </tbody></table>
          <table width="100%" cellSpacing="0"  cellPadding="0" border="0" className="customerinfo"><tbody>
              <tr><th valign="top">{t('robot_serial_number')}</th><td>{t1.serial_number}</td></tr>
              <tr><th valign="top">{t('robots_ibn_date')}</th><td>{t1.deliver_date}</td></tr>
              <tr><th valign="top">{t('robot_firmware_version')}</th><td>{t1.firmware_version}</td></tr>
              <tr><th valign="top">{t('info')}</th><td>{t1.info}</td></tr>
            </tbody></table>
        </div></div>  })) : null}
      </div>
    )
  }
}

export default Customer;