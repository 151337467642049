import TableFunctions from '../datatables/api/TableFunctions.js'
//import ReactTable from "react-table-6"
import Translation from '../user/Translation.js'
import GetJsonFormPHP, { PostJsonFormPHP, PostFileToPHP } from "../datatables/api/GetJsonFormPHP.js";
import UserProfile from '../user/UserProfile.js'
import '../datatables/styles/react-table.css'
//import { lighten } from '@mui/material';
import imgTrash from '../datatables/img/trash.png'

function t(field) { return Translation.get(field) }

class Serviceprotokoll extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/users/get_service_protokoll.php",
      saveurl: "/users/set_service_protokoll.php",
      uploadUrl: "/users/save_image.php",
      data: {city: "", }, empty: null, selected: -1,
      save: false, servicedate: '', userName: '',
      robotType: "ARANOM",
      classImg1Small: true, classImg2Small: true,
    }
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl + "?robot_id=" + UserProfile.getServiceRobotID() + "&protocol_id=" + UserProfile.getServiceReportID()).then((result) => {
      this.setState({
        data: result.data,
        save: result.save,
        servicedate: result.servicedate,
        robotType: result.robottype,
        userName: result.user,
      })
    })
  }
  handleInputChange(value, inputType = 0, min = null, max = null, decimals = null) {
    if (this.checkInputChars(value, inputType)) {
      const target = value.target.name
      let data = this.state.data
      let newVal = value.target.value
      let error = false
      if (min !== null && (inputType === 1 || inputType === 2) && value.target.value < min) error = true
      if (max !== null && (inputType === 1 || inputType === 2) && value.target.value > max) error = true
      if (inputType === 2) {
        if (decimals !== null && decimals > 0 && newVal !== "-" && newVal !== "+" && newVal !== ".") {
          newVal = newVal*10**decimals
          if (newVal < 0) newVal = Math.ceil(newVal)
          else  newVal = Math.floor(newVal)
          newVal = newVal/10**decimals
          if (parseFloat(value.target.value) === parseFloat(newVal)) newVal = value.target.value
        }
        else newVal = newVal*1;
      }
      if (inputType === 3) {
        let time = newVal.split(":");
        if (time[0] > 24) newVal = "24:"+time[1]
        if (time[1] > 59) newVal = time[0]+":59"
      }
      data.result[target] = newVal
      this.setState({
        data: data,
        fieldError: error,
      })
    }
  }
  handleChechboxChange(value) {
    let data = this.state.data
    data.result[value.target.name] = !data.result[value.target.name]
    this.setState({
      data: data,
    })
  }
  /*handleRatioChange(value) {
    let data = this.state.data
    data.result[value.target.name] = value.target.value
  }*/
  handleSave() {
    //console.log(this.state.saveurl,this.state.data.result)
    let data = this.state.data.result
    data.auger_oil_val = this.state.data.robot.auger_oil
    data.auger_bearing_val = this.state.data.robot.auger_bearing
    console.log(data)
    PostJsonFormPHP(this.state.saveurl, data).then((result) => {
    })
    this.props.closeDiv()
  }
  delImage(id) {
    GetJsonFormPHP(this.state.uploadUrl+"?type=service-picture&delimage=" +  id + "&protocol_id=" + UserProfile.getServiceReportID()).then((result) => {
      this.componentDidMount()
    })
  }
  handleImageUpload() {
    let file = document.getElementById("fileToUpload")
    PostFileToPHP(this.state.uploadUrl + "?type=service-picture&protocol_id=" + UserProfile.getServiceReportID(), file.files[0]).then((result) => {
      this.componentDidMount()
    })
  }

  render() {
    let img1 = "", img2 = ""
    if (this.state.data.result) {
      img1 = this.state.data.result['img1']
      img2 = this.state.data.result['img2']
      //console.log("result: ", this.state.data.result['img1'])
    }
    const inputChange = value => { this.handleInputChange(value) }
    const inputNumChange = value => { this.handleInputChange(value, 2, null, null, 2) }
    const checkboxChange = value => { this.handleChechboxChange(value) }
    //const ratioChange = value => { this.handleRatioChange(value) }
    /*const getLineStartup = (line, num) => {
      if (line.type === "head") return <tr><th  colSpan="5">{line.text} {num}</th></tr>
      if (line.type === "infoline") return <tr className="infoline" key={"tr_" + line.fieldname}><td className="infoline" colSpan="5"><b><span dangerouslySetInnerHTML={{__html: line.text}}></span></b></td></tr>
      if (line.type === "info") return headLine(line)
      else return calcLine(line, false)
    }*/
    const getLineService = (line) => {
      if (line.type === "head") return <tr><th  colSpan="5">{line.text}</th></tr>
      if (line.type === "infoline") return <tr className="infoline" key={"tr_" + line.fieldname}><td className="infoline" colSpan="5"><b><span dangerouslySetInnerHTML={{__html: line.text}}></span></b></td></tr>
      if (line.type === "info") return headLine(line)
      else return calcLine(line)
    }
    const headLine = (line) => {
      return <tr className="infoline" key={"tr_" + line.fieldname}><td colSpan="2" width="50%" className="infoline"><b><span dangerouslySetInnerHTML={{__html: line.text}}></span></b></td><td width="7%" className="infoline" align="center">OK</td><td width="8%" className="infoline" align="center">Tausch</td><td width="35%" align="center" className="infoline"><b>Info</b></td></tr>
    }
    const calcLine = (line, change = true) => {
      const nameChange = line.fieldname + "_c"
      //console.log(nameChange, this.state.data.result[nameChange])
      if (this.state.data && this.state.data.result[nameChange] === undefined) {
        let data = this.state.data
        data.result[nameChange] = false
        this.setState({ data: data, })
      }
      return (
        <tr className={line.optional === 1  && this.state.save ? "optional" : !this.state.save ? "old" : null} key={"tr_" + line.fieldname}>
          <td colSpan="2"><span dangerouslySetInnerHTML={{__html: line.text}}></span></td>
          <td align="center">{line.infofield === 1 ? this.state.save ? <input type="text" name={line.fieldname} onChange={inputNumChange} defaultValue={this.state.data.result[line.fieldname]} className="insert_med right" /> :  <input type="text" name={line.fieldname} value={this.state.data.result[line.fieldname]} className="insert_med right" /> : line.infofield !== -1 ?
            this.state.save ? <input type="checkbox" name={line.fieldname} value="1" className="checkbox" onChange={checkboxChange} defaultChecked={this.state.data.result[line.fieldname]}/> : <input type="checkbox" name={line.fieldname} value="1" className="checkbox" checked={this.state.data.result[line.fieldname]}/> : null}
          </td>
          {change ? <td align="center" className={line.force === 1 ? "force": null}>
            {line.exchange === 1 ? this.state.save ? <input type="checkbox" name={nameChange} value="1" className="checkbox" onChange={checkboxChange} defaultChecked={this.state.data.result[nameChange]} /> : <input type="checkbox" name={nameChange} value="1" className="checkbox" checked={this.state.data.result[nameChange]}/>: null}
          </td> : null}
          <td className="info">{line.optional === 1 ? <span>{t('serviceprotocol_optional')} &nbsp; <span dangerouslySetInnerHTML={{__html: line.info}}></span></span> : line.info ? <span dangerouslySetInnerHTML={{__html: line.info}}></span>  : null}</td>
        </tr>
      )
    }
    const calcLineScraper = (line, change = true) => {
      return (
        <tr className={line.optional === 1  && this.state.save ? "optional" : !this.state.save ? "old" : null} key={"tr_" + line.fieldname}>
          <td colSpan="2"><span dangerouslySetInnerHTML={{__html: line.text}}></span></td>
          <td align="left"><span dangerouslySetInnerHTML={{__html: line.text1}}></span></td>
          <td align="center">{line.infofield === 1 ? this.state.save ? <input type="text" name={line.fieldname} onChange={inputNumChange} defaultValue={this.state.data.result[line.fieldname]} className="insert_med right" /> :  <input type="text" name={line.fieldname} value={this.state.data.result[line.fieldname]} className="insert_med right" /> : line.infofield !== -1 ?
            this.state.save ? <input type="checkbox" name={line.fieldname} value="1" className="checkbox" onChange={checkboxChange} defaultChecked={this.state.data.result[line.fieldname]}/> : <input type="checkbox" name={line.fieldname} value="1" className="checkbox" checked={this.state.data.result[line.fieldname]}/> : null}
          </td>
          <td className="info">{line.optional === 1 ? <span>{t('serviceprotocol_optional')}</span> : line.info ? <span dangerouslySetInnerHTML={{__html: line.info}}></span> : null}</td>
        </tr>
      )
    }
    const company = this.state.data.company
    const robot = this.state.data.robot
    if (!company) return null
    else {
      let elementsTable1 = null, elementsTable2 = null, elementsTable3 = null, elementsTable4 = null, elementsTable5 = null, elementsTable6 = null, elementsTable7 = null, elementsTable8 = null, elementsTable9 = null, elementsTable10 = null, elementsTable11 = null, elementsTable12 = null, elementsTable13 = null, elementsTable14 = null, elementsTable15 = null, elementsTable16 = null
      if (this.state.robotType === "ARANOM") {
        elementsTable1 = this.state.data.lines.auger.map(function(line) { return getLineService(line) })
        elementsTable2 = this.state.data.lines.slider.map(function(line) { return getLineService(line) })
        elementsTable3 = this.state.data.lines.steering.map(function(line) { return getLineService(line) })
        elementsTable4 = this.state.data.lines.power.map(function(line) { return getLineService(line) })
        elementsTable5 = this.state.data.lines.general.map(function(line) { return getLineService(line) })
        elementsTable6 = this.state.data.lines.final.map(function(line) { return getLineService(line) })
      }
      if (this.state.robotType === "ARANOM_FEEDER") {
        elementsTable1 = this.state.data.lines.scraper.map(function(line) {
          if (line.type === "head") return <tr><th  colSpan="5">{line.text}</th></tr>
          if (line.type === "infoline") return <tr className="infoline" key={"tr_" + line.fieldname}><td className="infoline" colSpan="5"><b><span dangerouslySetInnerHTML={{__html: line.text}}></span></b></td></tr>
          if (line.type === "info") return headLine(line)
          if (line.type === "check1") return calcLineScraper(line)
          else return calcLine(line)
        })
        elementsTable2 = this.state.data.lines.storage.map(function(line) { return getLineService(line) })
      }
      if (this.state.robotType === "AVENGER") {
        elementsTable1 = this.state.data.lines.clean.map(function(line) { return getLineService(line, 1) })
        elementsTable2 = this.state.data.lines.display.map(function(line) { return getLineService(line, 2) })
        elementsTable3 = this.state.data.lines.auger.map(function(line) { return getLineService(line, 2) })
        elementsTable4 = this.state.data.lines.battery.map(function(line) { return getLineService(line, 2) })
        elementsTable5 = this.state.data.lines.chassis.map(function(line) { return getLineService(line, 2) })
        elementsTable6 = this.state.data.lines.rubber.map(function(line) { return getLineService(line, 2) })
        elementsTable7 = this.state.data.lines.water.map(function(line) { return getLineService(line, 2) })
        elementsTable8 = this.state.data.lines.crap.map(function(line) { return getLineService(line, 2) })
        elementsTable9 = this.state.data.lines.sensor.map(function(line) { return getLineService(line, 2) })
        elementsTable10 = this.state.data.lines.wings.map(function(line) { return getLineService(line, 2) })
        elementsTable11 = this.state.data.lines.flap.map(function(line) { return getLineService(line, 2) })
        elementsTable12 = this.state.data.lines.bar.map(function(line) { return getLineService(line, 2) })
        elementsTable13 = this.state.data.lines.plug.map(function(line) { return getLineService(line, 2) })
        elementsTable14 = this.state.data.lines.electric.map(function(line) { return getLineService(line, 2) })
        elementsTable15 = this.state.data.lines.charge.map(function(line) { return getLineService(line, 2) })
        elementsTable16 = this.state.data.lines.general.map(function(line) { return getLineService(line, 2) })
      }
      return (
        <div align="center" className="table_80">
          <input type="hidden" name="robot_id" value={robot.id} />
          <table className="service_table_main" width="100%">
            <tbody>
            <tr>
              <th width="75%">{t('service_protocol')} &nbsp; {this.state.servicedate}</th>
              <td rowSpan="2" className="logo"><img src="https://connect.hetwin.at/public/img/Hetwin_Logo.png" alt="Hetwin Logo" width="90%" align="center" /><br />
                <font size="-2">Mitterweg 15 | 6336 Langkampfen <br />
                 +43 5332 85300<br />
                 info@hetwin.at<br /></font>
              </td>
            </tr>
            <tr><td><hr />{robot.typename} &nbsp; &nbsp; {robot.serial_number}</td></tr>
            </tbody>
            </table> <br/>
            <table className="service_table" cellSpacing="0" cellPadding="0" width="100%">
              <tbody className={!this.state.save ? " old" : null}>
              <tr>
                <th colSpan="4">{t('service_customerdata')}</th>
              </tr>
              <tr>
                <td width="15%"><b>{t('add_customer_name')}</b></td>
                <td width="35%">{company.company_name}</td>
                <td width="20%"><b>Programmversion</b></td>
                <td width="30%">{robot.firmware_version}</td>
              </tr>
              <tr>
                <td rowSpan ="2"><b>{t('address')}</b></td>
                <td rowSpan ="2"><span dangerouslySetInnerHTML={{__html: company.company_address}}></span></td>
                <td><b>{t('service_run_hours')}</b></td>
                <td>{robot.run_hours} {t('hours')}</td>
              </tr>
              <tr>
                <td><b>kWh Akku</b></td>
                <td>{robot.accu} kWh</td>
              </tr>
              <tr>
                <td><b>Servicevertrag</b></td>
                <td><nobr><input type="radio" name="sevicecontract" value="1" className="checkbox" disabled />JA &nbsp; <input type="radio" name="sevicecontract" className="checkbox" value="0" checked />NEIN </nobr></td>
                <td><b>Seriennummer</b></td>
                <td>{robot.serial_number}</td>
              </tr>
              </tbody>
            </table><br />

            <table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>
              <tr><th>{t('userlevel_service')}</th></tr>
              <tr><td className="old"><b>{this.state.userName}</b></td></tr>
            </tbody></table>

            <h2 className="h2_Service">{robot.typename} &nbsp; &nbsp; {robot.serial_number}</h2>
            {elementsTable1 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable1}</tbody></table><br /></div> : null}
            {elementsTable2 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable2}</tbody></table><br /></div> : null}
            {elementsTable3 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable3}</tbody></table><br /></div> : null}
            {elementsTable4 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable4}</tbody></table><br /></div> : null}
            {elementsTable5 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable5}</tbody></table><br /></div> : null}
            {elementsTable6 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable6}</tbody></table><br /></div> : null}
            {elementsTable7 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable7}</tbody></table><br /></div> : null}
            {elementsTable8 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable8}</tbody></table><br /></div> : null}
            {elementsTable9 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable9}</tbody></table><br /></div> : null}
            {elementsTable10 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable10}</tbody></table><br /></div> : null}
            {elementsTable11 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable11}</tbody></table><br /></div> : null}
            {elementsTable12 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable12}</tbody></table><br /></div> : null}
            {elementsTable13 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable13}</tbody></table><br /></div> : null}
            {elementsTable14 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable14}</tbody></table><br /></div> : null}
            {elementsTable15 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable15}</tbody></table><br /></div> : null}
            {elementsTable16 !== null ? <div><table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{elementsTable16}</tbody></table><br /></div> : null}
            <table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="1"><tbody className={!this.state.save ? " old" : null}>
              <tr><th colSpan="5">Notizen</th></tr>
              <tr><td><textarea name="notices" cols="150" rows="6" onChange={inputChange} disabled={!this.state.save}> </textarea></td></tr>
              {img1 || img2 ? <tr><td>
                {img1 ?
                  <div className="service_img_div"><img src={"https://connect.hetwin.at/public/uploads/service_images/" + img1} alt="img1" className={this.state.classImg1Small ? "small_service_img" : null} onClick={() => {this.setState({classImg1Small: !this.state.classImg1Small})}} />
                  {this.state.save ? <span> &nbsp; &nbsp; <img src={imgTrash} alt={t("delete")} title={t("delete")} onClick={() => { this.delImage("img1") } } width="30" /></span> : null }</div>
                : null}
                {img2 ?
                  <div className="service_img_div"><img src={"https://connect.hetwin.at/public/uploads/service_images/" + img2} alt="img2" className={this.state.classImg2Small ? "small_service_img" : null} onClick={() => {this.setState({classImg2Small: !this.state.classImg2Small})}} />
                  {this.state.save ? <span> &nbsp; &nbsp; <img src={imgTrash} alt={t("delete")} title={t("delete")} onClick={() => { this.delImage("img2") } } width="30" /></span> : null } </div>
                : null }
              </td></tr> : null}
              <tr><td>
                {this.state.save && UserProfile.getServiceReportID() > 0 ?
                <div className="uploadform">
                  <input type="file" name="fileToUpload" id="fileToUpload" accept="image/png, image/gif, image/jpeg" /> &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <button onClick={() => {this.handleImageUpload()}} className="service_save">{t("submit")}</button>
                </div>
                : <div>
                  {this.state.data.img1 ? "IMG1<br>" : null}
                  {this.state.data.img2 ? "IMG2<br>" : null}
                </div> }
              </td></tr>
              <tr><td><span dangerouslySetInnerHTML={{__html: t('serviceprotocol_hint')}}></span></td></tr>
              {this.state.save ? <tr><td width="50%" align="center"><br ></br><button onClick={() => {this.handleSave()}} className="service_save">{t("save")}</button><br /><br /></td></tr> :null}
            </tbody></table><br />
          </div>

      )
    }
  }

}
/*
<form action={process.env.REACT_APP_API_URL +  "/" + this.state.uploadUrl} method="post" enctype="multipart/form-data" target="_blank">
                  Select Images to upload: &nbsp;
                  <input type="hidden" name="type" value="service-picture" />
                  <input type="hidden" name="protocol_id" value={UserProfile.getServiceReportID()} />
                  <input type="file" name="fileToUpload" id="fileToUpload" accept="image/png, image/gif, image/jpeg" />
                  <button onClick={() => {this.handleImageUpload()}} className="service_save">{t("submit")}</button><br />
                  <input type="submit" value={t("submit")} name="submit" />
                </form>
*/

export default Serviceprotokoll;
