import TableFunctions from './api/TableFunctions.js'
import ReactTable from "react-table-6"
import AlertDialog from "../user/Dialog.js"
import Draggable from "react-draggable"
import Translation from '../user/Translation'
import UserProfile from '../user/UserProfile.js'
import GetJsonFormPHP from "./api/GetJsonFormPHP"
import {setTableFontSize} from './api/imports.js'
import Info from "./api/Info"
import Select from 'react-select'
import imgDetail from './img/editdetail.png'
//import imgTrash from './img/trash.png';
import imgService from '../img/navi/org/service.png'
import imgService1 from '../img/navi/active/service.png'
import ServiceSheet from '../components/Serviceprotokoll.js'

import './styles/react-table.css'

function t(field) { return Translation.get(field) }


class Edit extends TableFunctions {
  constructor() {
    super()
    this.state = {
      geturl: "/robots/get_robotservices.php?robot_id=",
      saveurl: "/robots/set_robotservices.php",
      robot_id: null,
      row: null, data: null, empty: null, selected: null, // Table data
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      edit: false,
    //position: [],
    }
    this.handleClose = this.handleClose.bind(this)
    //this.handleEventDelete = this.handleEventDelete.bind(this)
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_window")
    e1.style.left = (window.scrollX+25*em)+'px'
    e1.style.top =(window.scrollY+20*em)+'px';
  }
  reload() {
    GetJsonFormPHP(this.state.geturl + this.state.row.robot_id).then((result) => {
      if (result.data === null) result.data = [this.state.empty]
      this.setState({
        data: result.data,
        selected: null,
      })
    })
  }
  handleClose() {
    this.props.closeDiv();
  }
  static getDerivedStateFromProps(props, state) {
    if (state.id !== props.row.id) {
      let empty = props.empty
      empty.robot_id = props.row.robot_id
      empty.customer_id = props.row.customer_id
      let data = props.row.services
      if (!data) data = empty
      return {
        index: props.index,
        id: props.row.id,
        row: props.row,
        data: data,
        empty: empty,
        selected: null,
        //position: props.position,
      }
    }
    return null
  }
  getServiceProtocol(robotID, id) {
    window.sessionStorage.setItem("service_robot_id", robotID)
    window.sessionStorage.setItem("service_report_id", id)
    this.props.loadProtocol();
    /*console.log(id, robotID);
    UserProfile.setServiceReport(robotID, id)
    this.setState({
      serviceCustomer: robotID,
      showServiceSheet: true,
    })*/
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    return [
      { accessor: 'id', show: false, },
      { accessor: 'customer_id', show: false, },
      { accessor: 'robot_id', show: false, },
      this.addTextCell('name', 'username', 0),
      { accessor: 'servicedate',
        show: true,
        Header: t('create_time'),
        width: 6.8*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const startService = value => { this.getServiceProtocol(row.robot_id, row.id) }
          return <div align="center" onClick={startService} className="link">{row.servicedate}</div>
          /*return (
            <div align="center">{row.last_protocol_date}
              <img src={row.days_left >  0 ? imgService: imgService1} alt="" title={t("serviceprotocol")} onClick={startService}/>
            </div>
          )*/
        },
      },
    ]
  }

  render() {
    //console.log(this.state.empty)
    if (this.state.id !== 0) {
      const supportRight = UserProfile.checkRight("support")
      const userRight = UserProfile.checkRight("custuser")
      const columns = this.createColumns()
      const saveData = value => { this.onFormSubmit() }
      let e1 = document.getElementById("edit_window")
      if (e1){
        const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
        e1.style.left = (window.scrollX+25*em)+'px'
        e1.style.top =(window.scrollY+20*em)+'px';
        //e1.style.left = (this.state.position[0]-28*em)+'px';
        //e1.style.top = (this.state.position[1]-2*em)+'px';
      }
      return (
        <Draggable handle=".handle">
        <div className="edittable" id="edit_window">
          <div className="handle"><div className="header">{t('home_robot')}: {this.state.row.name}<img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div></div>
          <form onSubmit={saveData}>
          <div className={supportRight ? "table_80": "table_60"}>
            <ReactTable
              data={this.state.data}
              columns={columns}
              minRows = {0}
              previousText= {'<'}
              nextText= {'>'}
              useFlexLayout = {true}
              showPaginationBottom={false}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                    onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && this.state.row.status < 5 && this.state.row.used === 0 && userRight) { this.setState({ selected: rowInfo.index, }) } },
                    style: {
                      background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white',
                      color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.id < 0 ? 'red' : 'black'
                    }
                }
              }}
            />
          </div>
          </form>
          <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        </div>
        </Draggable>
      )
    }
    else return null
  }
}


class Service extends TableFunctions {
  constructor() {
    super()
    this.state={
      geturl: "/robots/get_service.php",
      saveurl: "/robots/set_service.php",
      setTableUrl: "/users/change_tablesize.php",
      orgData: [], data: [], empty: null, selected: null,  // Table data
      showHelp: false, showHelpId: null, // Info popup
      emptyServices : null,
      customers: null, robottypes: null, // Select input options
      datatype: "robots",
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      fieldWidths: null, manageFields: false, tablePageSize: 0, // Show table fields
      robotFilter: 0, customerFilter: 0, customerSelect: null, robotSelect: null,  // Filter Options
      fontSize: setTableFontSize(),
      showServiceSheet: false, serviceCustomer: 1,
      //showPosition: [],
    }
    this.closeEdit = this.closeEdit.bind(this)
    this.reload = this.reload.bind(this)
    this.back = this.back.bind(this)
    this.showServiceProtokoll = this.showServiceProtokoll.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount(type = this.state.datatype) {
    GetJsonFormPHP(this.state.geturl + "?services=" + type).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      let pageSize = 25
      if (result.fieldwidth && result.fieldwidth._pageSize) pageSize = result.fieldwidth._pageSize
      //console.log(result)
      this.setState({
        orgData: result.data,
        data: result.data,
        robottypes: result.robottypes,
        customers: result.customers,
        customerSelect: result.customerselect,
        emptyServices: result.emptyservices,
        selected: null,
        fieldWidths: result.fieldwidth,
        tablePageSize: pageSize,
      })
    })
  }
  showServiceProtokoll() {
    //console.log(window.sessionStorage.getItem("service_robot_id"), window.sessionStorage.getItem("service_report_id"))
    const robotID = window.sessionStorage.getItem("service_robot_id")
    const id = window.sessionStorage.getItem("service_report_id")
    //this.getServiceProtocol(window.sessionStorage.getItem("service_robot_id"), window.sessionStorage.getItem("service_report_id"))
    UserProfile.setServiceReport(robotID, id)
    this.setState({
      serviceCustomer: robotID,
      showServiceSheet: true,
    })
  }


  getServiceProtocol(robotID, id) {
    //console.log(id, robotID);
    UserProfile.setServiceReport(robotID, id)
    this.setState({
      serviceCustomer: robotID,
      showServiceSheet: true,
    })
  }

  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const columns = [
      { accessor: 'id', show: false,},
      this.addTextCell('name', 'name', 0, 12*this.state.fontSize),
      this.addSelectCell("robottype_name", "robottype_id", this.state.robottypes, 8*this.state.fontSize,),
      this.addTextCell('service_start_h_offset', 'start_hours', 1, 6*this.state.fontSize),
      this.addTextCell('serviceinterval_h', 'interval_hours', 1, 6*this.state.fontSize),
      this.addTextCell('service_start_time_offset', 'start_days', 1, 6*this.state.fontSize),
      this.addTextCell('serviceinterval_time', 'interval_days', 1, 6*this.state.fontSize),
      this.addTextCell('serviceinterval_screw', 'interval_screw', 1, 6*this.state.fontSize),
      this.addTextCell('serviceinterval_engine', 'interval_engine', 1, 6*this.state.fontSize),
      this.addTextCell('serviceinterval_ejection', 'interval_ejection', 1, 6*this.state.fontSize),
      this.addTextCell('serviceinterval_steering', 'interval_steering', 1, 6*this.state.fontSize),
      this.addTextCell('serviceinterval_slot', 'interval_slot', 1, 6*this.state.fontSize),
      this.addTextAreaCell('service_info', 'infotext', 30, 3),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 6*em,
        filterable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false, UserProfile.checkRight("support"), UserProfile.checkRight("support")) },
      }
    ]
    return columns
  }
  createColumnsRobots() {
    //const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    const serviceRight = UserProfile.checkRight("service")
    const w = this.state.fieldWidths
    const fontSize = this.state.fontSize
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*fontSize
    const columns = [
      { accessor: 'id', show: false,},
      { accessor: 'robots', show: false,},
      { accessor: 'robot_id', show: false,},
      { accessor: 'services', show: false,},
      { accessor: 'last_protocol', show: false,},
      { accessor: 'service_sheet',
        show: serviceRight,
        Header: "",
        width: 5.8*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const startService = value => { this.getServiceProtocol(row.robot_id, "") }
          return (
            <div align="center">
              <img src={row.days_left >  0 ? imgService: imgService1} alt="" title={t("serviceprotocol")} onClick={startService}/>
            </div>
          )
        },
      },
      //this.addTextCell('customer_name', 'customer_id'),
      this.addSelectCell("customer", "customer_id", this.state.customers, (w && w['customer_id'] ? w['customer_id'] : 10)*fontSize, true, null, null, false, false),
      this.addSelectCell("robottype_name", "robottype_id", this.state.robottypes, (w && w['robottype_id'] ? w['robottype_id'] : 8)*fontSize, true, null, null, false, false),
      //this.addTextCell('name', 'name', 0, (w && w['name'] ? w['name'] : 12)*fontSize, true, "", null, null, null, false),
      this.addTextCell('robot_serial_number', 'serial_number', 0, (w && w['serial_number'] ? w['serial_number'] : 12)*fontSize, true, "", null, null, null, false),
      this.addDateCell('last_service', 'last_service_date', false, (w && w['last_service_date'] ? w['last_service_date'] : 5.5)*fontSize, true, "right", null, null, true),
      //this.addDateCell('next_service', 'next_service_date', false, 5.5, true, "right", null, null, false),
      this.addTextCell('service_run_hours', 'run_hours', 1, (w && w['run_hours'] ? w['run_hours'] : 6)*fontSize, true, "", null, null, null, false),
      this.addTextCell('service_last_service_hours', 'last_service_hours', 1, (w && w['last_service_hours'] ? w['last_service_hours'] : 6)*fontSize, true, "", null, null, null, false),
      this.addTextCell('service_days_left', 'days_left', 1, (w && w['days_left'] ? w['days_left'] : 6)*fontSize, true, "", null, "#D0E9D0", null, false),
      this.addTextCell('service_hours_left', 'hours_left', 1, (w && w['hours_left'] ? w['hours_left'] : 6)*fontSize, true, "", null, null, null, false),
      //this.addTextCell('serviceinterval_time', 'interval_days', 1, (w && w['interval_days'] ? w['interval_days'] : 6)*fontSize, true, "", null, null, null, false),
      this.addTextCell('service_total_distance', 'total_distance', 0, (w && w['total_distance'] ? w['total_distance'] : 6)*fontSize, true, "km", "right", null, null, false),
      this.addTextCell('service_steering_front', 'steering_front', 1, (w && w['steering_front'] ? w['steering_front'] : 6)*fontSize, true, "", null, null, null, false),
      this.addTextCell('service_steering_back', 'steering_back', 1, (w && w['steering_back'] ? w['steering_back'] : 6)*fontSize, true, "", null, null, null, false),
      this.addTextCell('service_auger', 'auger', 1, (w && w['auger'] ? w['auger'] : 6)*fontSize, true, "", null, null, null, false),
      //this.addTextCell('§hatch_left', 'hatch_left', 1, 6, true, "", null, null, null, false),
      //this.addTextCell('§hatch_right', 'hatch_right', 1, 6, true, "", null, null, null, false),
      //this.addTextCell('§feedkitchen', 'feedkitchen', 1, 6, true, "", null, null, null, false),
      //this.addTextCell('§feeding', 'feeding', 1, 6, true, "", null, null, null, false),
      //this.addTextCell('service_auger', 'auger', 1, (w && w['auger'] ? w['auger'] : 6)*fontSize, true, "", null, null, null, false),
      //this.addTextAreaCell('service_last_protocol1', 'last_protocol_date', (w && w['last_protocol_date'] ? w['last_protocol_date'] : 3)*fontSize, 1, true, null, null, null, false),
      this.addTextAreaCell('service_info', 'info', (w && w['info'] ? w['info'] : 0)*fontSize, 1, true, null, null, null, serviceRight),
      { accessor: 'last_protocol_date',
        show: serviceRight,
        Header: t('service_last_protocol'),
        width: 5.8*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const startService = value => { this.getServiceProtocol(row.robot_id, row.last_protocol) }
          if (row.last_protocol_date) return (
            <div align="center" onClick={startService} className="link">{row.last_protocol_date}</div>
          )
          else return null
          /*return (
            <div align="center">{row.last_protocol_date}
              <img src={row.days_left >  0 ? imgService: imgService1} alt="" title={t("serviceprotocol")} onClick={startService}/>
            </div>
          )*/
        },
      },
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 8*em,
        filterable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const getDetails = value => {
            this.setState({
              selectedRow: this.state.data[row._index],
              advancedIndex: row._index,
              showHideEdit: true,
              //showPosition: [value.clientX, value.clientY],
            })
          }
          //console.log(row.services)
          return <div>
            {this.edit(row, false, false, UserProfile.checkRight("trader"))}
            {row.services ? <img src={imgDetail} alt={t("animal_detail")} title={t("animal_detail")} onClick={getDetails}/> : null}
          </div>
        },
      }
    ]
    return columns
  }
  setfilter(value) {
    let filterCustomer = this.state.customerFilter
    let filterRobot = this.state.robotFilter
    if (value.name === "filter_customers") filterCustomer = value.value
    if (value.name === "filter_robots") filterRobot = value.value
    console.log(filterRobot)
    let  a = []
    if (filterCustomer > 0) {
      // eslint-disable-next-line array-callback-return
      this.state.orgData.map((t,k) => { if (t.customer_id === parseInt(filterCustomer) || t.id === -1)  a = [...a, t] })
    }
    else a = this.state.orgData
    let  a1 = []
    if (filterRobot > 0) {
      // eslint-disable-next-line array-callback-return
      a.map((t,k) => { if (t.robottype_id ===  parseInt(filterRobot) || t.id === -1) a1 = [...a1, t] })
    }
    else a1 = a
    this.setState({
      data: a1,
      robotFilter: filterRobot,
      customerFilter: filterCustomer,
    })
  }
  closeEdit() {
    this.setState({
      selected: null,
      showHideEdit: false,
    })
    this.componentDidMount(this.state.historic)
  }
  back() {
    this.setState({
      //serviceCustomer: id,
      showServiceSheet: false,
    })
  }
  render() {
    //console.log(this.state.empty)
    const traderRight = UserProfile.checkRight("trader")
    let columns = this.createColumns()
    if (this.state.datatype === "robots")  columns = this.createColumnsRobots()
    const changeType  = value => {
      let newType = ""
      if (this.state.datatype === "") newType = "robots"
      this.setState({datatype: newType,})
      this.componentDidMount(newType)
    }
    const selectChange  = value => { this.setfilter(value) }
    const changeColumnsWidth = (width, name) => {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
      if (this.state.datatype === "robots") GetJsonFormPHP(this.state.setTableUrl + "?table=services&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
      })
    }
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : data.bgcolor,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
      //input: (styles) => ({ ...styles, ...dot() }),
      //placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      //singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    }

    return (
      <div>
        {this.state.showServiceSheet ? <div><div align="center"><button onClick={() => {this.back()}} className="service_save only_support">{t("back")}</button></div><ServiceSheet closeDiv={this.back} /></div> :
        <div>
          <div className="table_buttons" valign="top">
            { this.state.datatype !== "robots" && UserProfile.checkRight("support") ? <div className="erors_inline"><button onClick={() => {this.insertRow()}}>{t("addline")}</button></div> : null}
            <button onClick={(value) => {changeType()}}>{t("service_change_view")}</button>
              { traderRight && this.state.orgData.length > 2 && this.state.datatype === "robots" ?
              <div className="erors_inline">
                <Select
                  options={this.state.customerSelect}
                  styles={colourStyles}
                  isSearchable={true}
                  isClearable={true}
                  className="chartselect em_20"
                  onChange={(value) => {selectChange({name: "filter_customers", value: value !== null ? value.value : 0})}}
                  placeholder={t('filter_customer')}
                />
              </div> : null }
              { traderRight && this.state.orgData.length > 2  && this.state.datatype === "robots" ?
                <div className="erors_inline">
                <Select
                  options={this.state.robottypes}
                  styles={colourStyles}
                  isSearchable={true}
                  isClearable={true}
                  className="chartselect em_15"
                  onChange={(value) => {selectChange({name: "filter_robots", value: value !== null ? value.value : 0})}}
                  placeholder={t('filter_robots')}
                />
              </div>
            : null }
            <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_service_table", }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div>
          </div>
          {this.state.tablePageSize > 0 ?
          <div style={{fontSize: this.state.fontSize+"em"}}>
            <ReactTable
              data={this.state.data}
              columns={columns}
              minRows = {0}
              previousText= {'<'}
              nextText= {'>'}
              showPageJump= {true}
              defaultPageSize = {this.state.tablePageSize}
              pageSizeOptions = {[10, 20, 50, 100]}
              useFlexLayout = {true}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                    onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && UserProfile.checkRight("support")) { this.setState({ selected: rowInfo.index, }) } },
                    style: {
                      background: rowInfo && rowInfo.index === this.state.selected ? 'rgba(234, 234, 255, 1)' : rowInfo.row.customer_id === 2 ? 'rgba(244, 244, 255, 1)' : this.getRobotColor(rowInfo.row.robottype_id),
                      color: rowInfo &&  rowInfo.index === this.state.selected ? this.getColor('selected') : this.state.datatype === "" ?  'black' : rowInfo.row.days_left > 0 ? rowInfo.row.days_left > 30 ? rowInfo.row.customer_id === 2 ? 'rgba(184, 184, 195, 1)' :'black' : 'DarkGoldenRod' : 'FireBrick',
                      fontSize:  rowInfo && (rowInfo.row.robottype_id === 2 || rowInfo.row.robottype_id === 10 || rowInfo.row.robottype_id === 12) ? "0.8em" : null,
                    }
                }
              }}
              getTheadTrProps={(state, column, instance) => {
                return {
                  onClick:  (e) => { if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText) },
                }
              }}
              getProps = {(state, column, instance) => {
                return {
                  onChange:  (e) => { if (e.target.type !== "number" && e.target.type !== "text" && e.target.name === "") changeColumnsWidth(e.target.value, "_pageSize") },
                }
              }}
            />
          </div>
          : null}
          {this.state.showHideEdit && <Edit row = {this.state.selectedRow} index = {this.state.advancedIndex} empty = {this.state.emptyServices} closeDiv={this.closeEdit} reload={this.reload} loadProtocol={this.showServiceProtokoll} />}
          {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
          <AlertDialog openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        </div>
      }</div>
    )
  }
}
export default Service;
