import React, { Component } from 'react'
import '../styles/Login.css'
import '../datatables/styles/tablestyles.css'
import Draggable from "react-draggable"
import GetJsonFormPHP, { PostJsonFormPHP } from "../datatables/api/GetJsonFormPHP"
import Translation from '../user/Translation'
import UserProfile from '../user/UserProfile'
import hetwinHeaad from '../img/Hetwin_Head.png'
import imgClose from '../datatables/img/close.png'

function t(field) { return Translation.get(field) }

class NewPassword extends Component {
  constructor() {
    super();
    this.state={
      passwordUrl: "/users/request_password.php",
      login: "",
    }
    this.handleClose = this.handleClose.bind(this)
  }
  handleClose() {
    this.setState({login: "", })
    this.props.closeDiv()
  }
  static getDerivedStateFromProps(props, state) {
    if (!state.login) return { login: props.login }
    else return null
  }

  render() {
    const sendRequest = () => {
      PostJsonFormPHP(this.state.passwordUrl, this.state.login).then((result) => {
        if (result.error) {
          this.setState({
            title: t("error"),
            message: t(result.error),
          })
        }
        this.handleClose()
      })
    }
    const changeValue = value => { this.setState({login: value.target.value, })}
    return (
      <Draggable handle=".handle">
        <div className="login-wrapper-window password_request_window">
          <div className="handle"><div className="headline">{t('register_password_request')}<img src={imgClose} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/close.png")}} /></div></div>
          <div className="body">
            {t('login_username')}
            <input type="text" name="login" value={this.state.login} onChange={changeValue} className="longinput"/>
          </div>
          <div align="center"><button onClick={() => {sendRequest()}}>{t("login_send")}</button></div><br />
        </div>
      </Draggable>
    )

  }
}

class Registration extends Component {
  constructor() {
    super();
    this.state={
      registerUrl: "/users/register.php",
      code: "", email: "", exchange: false, login: "", name: "", address: "",
      copyMail: false,
    }
    this.handleClose = this.handleClose.bind(this)
    this.sendRequest = this.sendRequest.bind(this)
  }
  handleClose() {
    this.props.closeDiv()
  }
  sendRequest() {
    //console.log(localStorage)
    const data ={
      code: this.state.code,
      name: this.state.name,
      address: this.state.address,
      email: this.state.email,
      login: this.state.login,
      lan: localStorage.getItem('language_id'),
    }
    //console.log(data)
    PostJsonFormPHP(this.state.registerUrl, data).then((result) => {
      if (result.error) {
        this.setState({
          title: t("error"),
          message: t(result.error),
        })
      }
      this.handleClose()
    })
  }
  static getDerivedStateFromProps(props, state) {
    return {}
  }
  render() {
    const changeValue = value => {
      if (value.target.name === "code") this.setState({code: value.target.value, })
      if (value.target.name === "name") this.setState({name: value.target.value, })
      if (value.target.name === "address") this.setState({address: value.target.value, })
      if (value.target.name === "email") {
        this.setState({email: value.target.value, })
        if (this.state.copyMail) this.setState({login: value.target.value, })
      }
      if (value.target.name === "login") this.setState({login: value.target.value, })
      if (value.target.name === "dataExchange") this.setState({ exchange: !this.state.exchange, })
      if (value.target.name === "copyemail") {
        if (!this.state.copyMail) this.setState({login: this.state.email, })
        else this.setState({login: "", })
        this.setState({ copyMail: !this.state.copyMail, })
      }
    }
    return (
      <Draggable handle=".handle">
        <div className="login-wrapper-window register_window">
        <div className="handle"><div className="headline">{t('register_request')}<img src={imgClose} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/close.png")}} /></div></div>
        <div className="body">
          <table width="90%" border="0">
            <tr><td>{t('register_code')}: </td><td>
            <input type="text" name="code" value={this.state.code} onChange={changeValue} className="longinput"/></td></tr>
            <tr><td colSpan="2">*oder*</td></tr>
            <tr><td>*Name: </td><td className="alternative"><input type="text" name="name" value={this.state.name} onChange={changeValue} className="longinput"/></td></tr>
            <tr><td>*Adresse: </td><td className="alternative"><input type="text" name="address" value={this.state.address} onChange={changeValue} className="longinput"/></td></tr>
            <tr><td>{t('email')}:</td><td>
            <input type="text" name="email" value={this.state.email} onChange={changeValue} className="longinput"/></td></tr>
            <tr><td>{t('register_login')}:</td><td>
            <input type="text" name="login" value={this.state.login} onChange={changeValue} disabled={this.state.copyMail} className="longinput"/>
            &nbsp; <input type="checkbox" name="copyemail" value="1" className="checkbox" checked={this.state.copyMail} onChange={changeValue} />{t('register_use_mail')}</td></tr>
          </table><br></br>
          <textarea readOnly rows="15" cols="100" className="login_security_text" value={t('register_security_declaration')}></textarea><br></br>
          <input type="checkbox" name="dataExchange" checked={this.state.exchange} onChange={changeValue} value="1" className="checkbox"/>{t('register_security_read')}
        </div>
        <div>
          <button onClick={this.sendRequest} disabled={!(this.state.exchange && (this.state.code.length === 10 || (this.state.name.length >= 5 && (this.state.address.length >= 10 ))) && this.state.email.length > 8)}>{t("register_send")}</button>
        </div>
        </div>
      </Draggable>
    )
  }
}


class Login extends Component{
  constructor() {
    super()
    this.state={
      loginUrl: "/users/login.php",
      logoutUrl: "/users/logout.php",
      customerUrl: "/users/get_customers.php",
      setCustomerUrl: "/users/set_customer_id.php",
      translationUrl: "/users/translations.php",
      languagesUrl: "/users/load_languages.php",
      customers: null,
      login: "",
      password: "",
      info: "",
      loginStatus: false,
      language: null,
      reload: 0,
      showHideEdit: false, showHideRegister: false,
      languages: null,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.getLogin = this.getLogin.bind(this)
    this.customerChange = this.customerChange.bind(this)
    this.changeLanguage = this.changeLanguage.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.closeDiv = this.closeDiv.bind(this)
  }

  componentDidMount() {
    if (this.state.languages === null) {
      GetJsonFormPHP(this.state.languagesUrl).then((result) => {
        if (result.lans) {
          this.setState({
            languages: result.lans,
          })
        }
      })
    }
    if (Translation.getLanguage() === null) {
      GetJsonFormPHP(this.state.translationUrl + '?login=1&lan=en').then((result) => {
        Translation.set("en", result.data)
        this.setState({
          language: "en",
        })
      })
    }
    let clear = false
    if (UserProfile.getId() !== null)  clear = true
    if (clear) {
      GetJsonFormPHP(this.state.logoutUrl)
      //console.log(UserProfile.getId())
      UserProfile.clearProfile()
      Translation.clear()
      window.location.href="/"
    }
  }
  handleInputChange(event) {
    const target = event.target;
    if (target.name === "login") {
      this.setState({
        login: target.value,
        info: "",
      })
    }
    else {
      this.setState({
        password: target.value,
        info: "",
      })
    }
  }
  onKeyPress(event) {
    if (event.charCode === 13) this.getLogin(null)
  }
  changeLanguage(lan) {
    //console.log(lan)
    if (UserProfile.getId() > 0) {
      GetJsonFormPHP(this.state.translationUrl + '?lan=' + lan).then((result) => {
        Translation.set(result.language_id, result.data)
      })
    }
    else if (lan !== this.state.language) {
      GetJsonFormPHP(this.state.translationUrl + '?login=1&lan='+lan).then((result) => {
        //console.log("set new lan")
        Translation.set(result.language_id, result.data)
        this.setState(
          {language: lan, }
        )
      })
    }
  }
  getLogin(event) {
    if (event !== null) event.preventDefault()
    let loginData = {login: this.state.login, password: this.state.password, }
    PostJsonFormPHP(this.state.loginUrl, loginData).then((result) => {
      if (result.id) {
        this.setState({
          loginStatus: true,
          info: "",
        })
        UserProfile.setProfile(result, this.state.login)
        GetJsonFormPHP(this.state.translationUrl + '?lan='+result.language_id).then((result) => {
          Translation.clear()
          Translation.set(result.language_id, result.data)
          window.location.reload(false)
        })
        //window.location.reload(false)
      }
      else  {
        let error = t("login_failed")
        if (result.error === "customer_inactive") error = t("customer_disable")
        if (result.error === "customer_timeout") error = t("customer_abo_timeout")
        this.setState({
          password: "",
          info: error,
        })
        //console.log (result.error)
      }
      //console.log(sessionStorage)
    })
  }
  customerChange(event) {
    const id = event.target.value
    if (event !== null) event.preventDefault()
    GetJsonFormPHP(this.state.setCustomerUrl + '?id=' + id).then((result) => {
      if (result.succeed) {
        this.setState({
          login: id,
          loginStatus: true,
          info: "",
        })
        console.log(result)
        UserProfile.setProfile(result, this.state.login)
        //window.location.reload()
      }
      else  {
        let error = t("login_failed")
        if (result.error === "customer_inactive") error = t("customer_disable")
        if (result.error === "customer_timeout") error = t("customer_abo_timeout")
        this.setState({
          password: "",
          info: error,
        })
      }
    })
  }
  closeDiv() {
    this.setState({ showHideEdit: false, showHideRegister: false, })
  }
  render() {
    const custs = this.state.customers
    //console.log(window.sessionStorage)
    const logout = value => {
      GetJsonFormPHP(this.state.logoutUrl)
      UserProfile.clearProfile()
      Translation.clear()
      window.location.href="/"
    }
    return(
      <div className="login-window">
        <div align="right" width="30%">
          { this.state.languages !== null ? Object.entries(this.state.languages).map((t,k) => <img key={"imglan"+k} src={require("../img/flags/"+t[1].icon)} alt={t[1].fieldname} className="img_languages" onClick={() => { this.changeLanguage(t[1].id) }} />) : ""} 
          <br/>
        </div>
        {custs ?
        <div>
          <select name="userselect"className="customer_select" value={UserProfile.getCustomer()} onChange={this.customerChange}>
            { Object.entries(custs).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) }
          </select><br /><br />
          <button type="button" className="login_button" onClick={logout}>{t('login_out')}</button>
        </div> :
        <div className="login-wrapper">
          <div align="center"><img src={hetwinHeaad} alt="Hetwin Logo" width="100px" /></div>
          <div align="left" className="welcome">{t("login_welcometext")}</div>
          <form onSubmit={this.getLogin} width="100%">
            <div align="left">{t("login_username")}:</div>
            <div>
              <input autoFocus type="text" name="login" value={this.state.login} onChange={this.handleInputChange} onKeyPress={this.onKeyPress} autoComplete="on"/>
            </div>
            <div align="left">{t('login_password')}:</div>
            <div>
              <input type="password" name="password" value={this.state.password} onChange={this.handleInputChange} onKeyPress={this.onKeyPress} autoComplete="on"/><br />
              <div className="error">{this.state.info} </div>
            </div>
            <div align="center">
              <button type="button" className="login_button" onClick={this.getLogin}>{t('login_submit')}</button>
            </div>
          </form>
          <div align="center">
            <button onClick={() => {this.setState({ showHideEdit: true })}}>{t("login_forgot")}</button>
          </div>
          {1 ==2 ?
          <div>
            <button onClick={() => {this.setState({ showHideRegister: true })}}>{t("register_request")}</button>
          </div>
          : null }
          {this.state.showHideEdit && <NewPassword login={this.state.login} closeDiv={this.closeDiv}/>}
          {this.state.showHideRegister && <Registration closeDiv={this.closeDiv} />}
          </div>
        }
      </div>
    )
  }
}

export default Login