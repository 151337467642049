import React, { Component } from 'react';
import { Route, Routes, Link, useLocation, useParams } from 'react-router-dom';
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';

import RecipeList from '../datatables/RecipeList.js';
import RobotFeedList from '../datatables/RobotFeedList.js';
import FeedList from '../datatables/FeedList.js';
import SiloList from '../datatables/SiloList.js';
import OrderList from '../datatables/OrderList.js';
import Contact from './Contact.js';

function t(field) { return Translation.get(field) }

function SideBar() {
  const location = useLocation()
  let first = location.pathname.substring(1, location.pathname.length)
  let second = null
  if (first.indexOf("/") > 0) {
    second = first.substring(first.indexOf("/")+1, first.length+1)
    first = first.substring(0, first.indexOf("/"))
  }
  function over(e) {
    switch(e.target.id) {
      case "nav_feed":      e.currentTarget.src =  require("../img/navi/mouseover/feed.png"); break;
      case "nav_recipe":    e.currentTarget.src =  require("../img/navi/mouseover/recipe.png"); break;
      case "nav_robotfeed": e.currentTarget.src =  require("../img/navi/mouseover/feeding.png"); break;
      case "nav_order":     e.currentTarget.src =  require("../img/navi/mouseover/order.png"); break;
      case "nav_silo":      e.currentTarget.src =  require("../img/navi/mouseover/silo.png"); break;
      case "nav_contact":   e.currentTarget.src =  require("../img/navi/mouseover/contact.png"); break;
      default:
    }
  }
  function out(e) {
    switch(e.target.id) {
      case "nav_feed":      if (second === "feed") e.currentTarget.src = require("../img/navi/active/feed.png"); else e.currentTarget.src =  require("../img/navi/feed.png"); break
      case "nav_recipe":    if (second === "recipe") e.currentTarget.src = require("../img/navi/active/recipe.png"); else e.currentTarget.src =  require("../img/navi/recipe.png"); break
      case "nav_robotfeed": if (second === "robotfeed") e.currentTarget.src = require("../img/navi/active/feeding.png"); else e.currentTarget.src =  require("../img/navi/feeding.png"); break
      case "nav_order":     if (second === "orders") e.currentTarget.src = require("../img/navi/active/order.png"); else e.currentTarget.src =  require("../img/navi/order.png"); break
      case "nav_silo":      if (second === "silo") e.currentTarget.src = require("../img/navi/active/silo.png"); else e.currentTarget.src =  require("../img/navi/silo.png"); break
      case "nav_contact":   if (second === "contact") e.currentTarget.src = require("../img/navi/active/contact.png"); else e.currentTarget.src =  require("../img/navi/contact.png"); break
      default:
    }
  }
  return (
    <div className="nav_left_main">
      <Link to="recipe"><img id="nav_recipe" src={second === "recipe" ? require("../img/navi/active/recipe.png") : require("../img/navi/recipe.png")} alt={t('nav_recipe')} title={t('nav_recipe')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="robotfeed"><img id="nav_robotfeed" src={second === "robotfeed" ? require("../img/navi/active/feeding.png") : require("../img/navi/feeding.png")} alt={t('nav_robotfeed')} title={t('nav_robotfeed')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="feed"><img id="nav_feed" src={second === "feed" ? require("../img/navi/active/feed.png") : require("../img/navi/feed.png")} alt={t('nav_feed')} title={t('nav_feed')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="silo"><img id="nav_silo" src={second === "silo" ? require("../img/navi/active/silo.png") : require("../img/navi/silo.png")} alt={t('nav_silo')} title={t('nav_silo')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="orders"><img id="nav_order" src={second === "orders" ? require("../img/navi/active/order.png") : require("../img/navi/order.png")} alt={t('nav_orders')} title={t('nav_orders')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="contact" className="bottom"><img className="bottom" id="nav_contact" src={second === "contact" ? require("../img/navi/active/contact.png") : require("../img/navi/contact.png")} alt={t('nav_contact')} title={t('nav_contact')} onMouseOver={over} onMouseOut={out}/></Link>
    </div>
  )
}

class DetailsPage extends Component {
  render() {
    //console.log("I am here!",this.props.id )
    //console.log(process.env)
    return(
      <div>
        <h2>Hallo</h2>
        <p>xx</p>
      </div>
    )
  }
}

function Child() {
  let { id } = useParams();
  return (
      <DetailsPage id={id}/>
  );
}

class Feeds extends Component {
  render()
  {
    //<Route path=":id" element={<DetailsPage />}/>
    //<Route exact path="/details/:id" render={(props) => <DetailsPage globalStore={globalStore} {...props} /> } />
    if (UserProfile.showFeeds() === "true") {
    return (
      <div>
        <div className="nav2">
          <nav id="navigation_2">< SideBar /></nav>
        </div>
        <div className="content">
        <div className="body">
        <div className="tablebody">
          <Routes>
            <Route path="" element={<RecipeList />} />
            <Route path="recipe" element={<RecipeList />} />
            <Route path="robotfeed" element={<RobotFeedList />} />
            <Route path="feed" element={<FeedList />} />
            <Route path="silo" element={<SiloList />} />
            <Route path="orders" element={<OrderList />} />
            <Route path="contact" element={<Contact />}/>
            <Route path=":id" element={<Child />}/>
          </Routes>
        </div>
      </div>
        </div>
      </div>
    )
    }
    else return ( "null" )
  }
}

export default Feeds;