import React from 'react'
import TableFunctions from '../datatables/api/TableFunctions.js'
import ReactTable from "react-table-6"
import AlertDialog from "../user/Dialog.js"
//import ManageCols from "../datatables/api/ManageFields"
import Customer from './Customer.js'
import Translation from '../user/Translation.js'
import Select from 'react-select'
import Info from "../datatables/api/Info.js"
import UserProfile from '../user/UserProfile.js'
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP.js"
import {setTableFontSize} from '../datatables/api/imports.js'

import '../datatables/styles/tablestyles.css'
import '../datatables/styles/react-table.css'
import imgSupport from '../datatables/img/support.png'
import imgRemote from '../datatables/img/pc-visit.png'
import imgBug from '../datatables/img/bug_gold.png'
import imgFalse from '../datatables/img/false.png'
import imgFalse2 from '../datatables/img/false2.png'
import imgTrue from '../datatables/img/true.gif'
import imgImporter from '../datatables/img/importer.png'
import imgTrash from '../datatables/img/trash.png'
import imgCustomer from '../datatables/img/change_cust.png'

function t(field) { return Translation.get(field) }

class Customers extends TableFunctions {
  constructor() {
    super()
    let fontSize = 1
    if (UserProfile.checkRight("trader")) fontSize = 0.9
    this.state={
      geturl: "/users/get_customers.php?support=1",
      saveurl: "/users/set_customer.php",
      setCustomerUrl: "/users/set_customer_id.php",
      setTableUrl: "/users/change_tablesize.php",
      orgData: [], data: [], empty: null, selected: null,  // Table data
      customers: null, trader: null, services: null, languages: null, countries: null, country: null,  // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      showfields: null, fieldWidths: null, manageFields: false, tablePageSize: 0, // Show table fields
      filterRobot: '', filterCustomer: "", filterCountry: 0, customerSelect: null, // Filter Options
      apiData: null, favorites: {'favoritesA': [false, false, false, false, false, false, false, false, ], 'favoritesFG': [false, false, false, false, ], 'favoritesF': [false, false, false, false, false, ],},
      showHelp: false, showHelpId: null, // Info popup
      fontSize: setTableFontSize(fontSize),
      showCustomer: -1,
    }
    this.back = this.back.bind(this)
    this.getCustomer = this.getCustomer.bind(this)
    this.reload = this.reload.bind(this)
  }

  reload() { this.componentDidMount() }

  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      let pageSize = 25
      if (result.fieldwidth && result.fieldwidth._pageSize) pageSize = result.fieldwidth._pageSize
      let data = result.data
      data = this.setfilter(null, data)
      this.setState({
        orgData: result.data,
        data: data,
        customers: result.customers,
        //trader: result.trader,
        //service: result.service,
        countries: result.countries,
        country: result.countrylist,
        languages: result.languages,
        fieldWidths: result.fieldwidth,
        showfields: result.showfields,
        tablePageSize: pageSize,
        robots: result.robotlist,
        //apiData: result.apis,
        favorites: {'favoritesA': result.pages.A, 'favoritesFG': result.pages.F, 'favoritesF': result.pages.FG,},
      })
    })
    this.selectedCheckboxes = new Set();
  }

  getCustomer(id) {
    //window.sessionStorage.setItem("service_robot_id", robotID)
    //window.sessionStorage.setItem("service_report_id", id)
    this.setState({
      showCustomer: id,
    })
    //this.props.loadProtocol();
    /*console.log(id, robotID);
    UserProfile.setServiceReport(robotID, id)
    this.setState({
      serviceCustomer: robotID,
      showServiceSheet: true,
    })*/
  }

  radioboxCell(initialValue, id, row, value, options) {
    let adminRight = UserProfile.checkRight("admin") || UserProfile.checkRight("superuser")
    let value3 = -1
    if (id === "is_trader") {
      adminRight = true
      value3 = 2
    }
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (this.state.selected === row._index) {
      return (
        <div>
          {options[2] && adminRight ? <label><input type="radio" name={id} value={value3} className="checkbox" checked={parseInt(value) === value3} onChange={inputChange} />{options[2]}<br /></label> : null}
          <label><input type="radio" name={id} value="1" className="checkbox" checked={parseInt(value) === 1} onChange={inputChange} />{options[1]}</label><br />
          <label><input type="radio" name={id} value="0" className="checkbox" checked={parseInt(value) === 0} onChange={inputChange} />{options[0]}</label>
        </div>
      )
    }
    else {
      if (initialValue === 1) return (<div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>)
      else {
        if (initialValue === 0) {
          if (id === "is_trader") return null
            else return (<div width="100%" align="center"><img src={imgFalse2} alt={t("disabled")} title={t("disabled")} /></div>)
        }
        if (initialValue === 2 || initialValue === 10) return (<div width="100%" align="center"><img src={imgImporter} alt={t("importer")} title={t("importer")} /></div>)
        if (initialValue === -1) return (<div width="100%" align="center"><img src={imgTrash} alt={t("delete")} title={t("delete")} /></div>)
        else return (<div width="100%" align="center"><img src={imgFalse} alt={t("deleted")} title={t("deleted")} /></div>)
      }
    }
  }
  setCustomer(id) {
    //console.log("change cust", id)
    GetJsonFormPHP(this.state.setCustomerUrl + '?id=' + id).then((result) => {
      if (result.succeed) {
        UserProfile.setProfile(result, this.state.login)
        window.location.reload(false)
      }
    })
  }

  createColumns() {
    const suRight = UserProfile.checkRight("superuser")
    const adminRight = UserProfile.checkRight("admin") || suRight
    const supportRight = UserProfile.checkRight("support")
    const traderRight = UserProfile.checkRight("trader")
    const subtraderRight = UserProfile.checkRight("addcust")
    //const denyServiceRight = !UserProfile.checkRight("service") || subtraderRight
    const fontSize = this.state.fontSize
    const w = this.state.fieldWidths
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*fontSize
    let adminWidth = 8.4
    if (adminRight) adminWidth = 8.4 //11.0
    const columns = [
      { accessor: 'id', show: false, },
      //{ accessor: 'customer_id', show: true,},
      { accessor: 'show',
        show: subtraderRight,
        Header: "",
        width: adminWidth*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const customerChange = value => { this.setCustomer(row.id) }
          const startService = value => { this.getCustomer(row.id) }
          return (
            <div align="left">
              <img src={imgCustomer} alt="" title={t("customer_info")} onClick={startService}/>
              {UserProfile.getUserLevel() <= 4 && (row.is_trader === 0 || UserProfile.getUserLevel() <= 2) && row.id !== 1 ? <img src={imgSupport} alt="" title={t("customer_change")} onClick={customerChange}/> : null}
              {supportRight ? <span>{row.remote_id && row.remote_id.length > 20 ? <a href={"https://mobile.pcvisit.de/computer/" + row.remote_id} target="_blank" rel="noreferrer"><img src={imgRemote} alt="" title={t("customer_remote")} className="pcvisit"/></a> : null}</span>: null}
              {adminRight && row.is_trader === 999 ? <span>
                <a href={"https://connect.hetwin.at/portalapitest/import/robot_imports.php?customer=" + row.id} rel="noreferrer" target="_blank"><img src={imgBug} alt="" title="Datenlogs" /></a>
              </span> : null}
            </div>
          )
        },
      },
      this.addTextCell('customer', 'company_name', 0, (w && w['company_name'] ? w['company_name'] : 16)*fontSize, true, "", null, null, null, supportRight),
      this.addTextCell('customer_robots', 'robots', 0, (w && w['robots'] ? w['robots'] : 8)*fontSize, true, "", null, null, null, supportRight),
      { accessor: 'robotslist',
        show: true,
        Header: "",
        width: 4*em,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const startService = value => { console.log(value.target.id); this.getCustomer(value.target.id) }
          //{row.robotslist.map((t,k) => {  console.log(t, k); return "*" })}
          if (row.robotslist !== undefined) {
            //console.log(row.robotslist)
            return (
                <div>{row.robotslist.map((t,k) => { return <div id={t} onClick={startService}>{t}</div> })}</div>
              )
          }
          else return null
        }
      },
      //this.addSelectCell('add_customer_country', 'country', this.state.countries, (w && w['country'] ? w['country'] : 8)*fontSize, true, null, null, false, subtraderRight),
      //this.addTextAreaCell('address', 'company_address', (w && w['company_address'] ? w['company_address'] : 16)*fontSize, 2, this.state.showfields ? this.state.showfields['company_address'] : true, null, null, null, denyServiceRight),
      { accessor: 'company_address', show: false, },
      //this.addTextAreaCell('contact', 'contact', (w && w['contact'] ? w['contact'] : 12)*fontSize, 2, this.state.showfields ? this.state.showfields['contact'] : true, null, null, null, denyServiceRight),
      //this.addTextCell('email', 'email', 0, (w && w['email'] ? w['email'] : 15)*fontSize, this.state.showfields ? this.state.showfields['email'] : true, null, null, null, null, denyServiceRight),
      //this.addTextCell('telephone', 'phone', 0, (w && w['phone'] ? w['phone'] : 10)*fontSize, this.state.showfields ? this.state.showfields['phone'] : true),
      //this.addTextAreaCell('customer_info', 'info', (w && w['info'] ? w['info'] : 12)*fontSize, 2, this.state.showfields ? subtraderRight && this.state.showfields['info'] : subtraderRight, null, null, null, supportRight),
      { accessor: 'is_trader', show: false, },
      /*{ accessor: 'enabled',
        Header: t('enabled'),
        width:  (w && w['enabled'] ? w['enabled'] : 5)*em,
        show: subtraderRight,
        filterable: false,
        sortable: true,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const ID = parseInt(UserProfile.getCustomer())
          if (row.id !== ID) return this.radioboxCell(initialValue, id , row, row.enabled, [t("disabled"), t("enabled"), t("delete")])
          return <div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>
        },
      },*/
      //this.addTextCell('remote_name', 'remote_id', 0, (w && w['remote_id'] ? w['remote_id'] : 7.2)*fontSize,  this.state.showfields ? adminRight && this.state.showfields['remote_id'] : adminRight, "", null, this.getColor('cellAdmin')),
      this.addDateCell('customer_abo_time', 'abo_time', false, (w && w['abo_time'] ? w['abo_time'] : 7.2)*fontSize, this.state.showfields ? this.state.showfields['abo_time'] : true, "center", null, null, supportRight),
      this.addSelectCell("trader", "trader_id", this.state.trader, (w && w['trader_id'] ? w['trader_id'] : 12)*fontSize, this.state.showfields ? traderRight && this.state.showfields['trader_id'] : traderRight, null, null, true, supportRight),
      { accessor: 'importer_id', show: false,},
      //this.addSelectCell("customer_support", "support", this.state.service, (w && w['support'] ? w['support'] : 12)*fontSize, this.state.showfields ? traderRight && this.state.showfields['support'] : traderRight, null, null, true, supportRight),
      //this.addDateCell('§Online', 'online_time', false, (w && w['online_time'] ? w['online_time'] : 7.2)*fontSize, this.state.showfields ? supportRight && this.state.showfields['online_time'] : supportRight, "center", null, null, false),
    ]
    return columns
  }
  dateToDays(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
    return parseInt(year)*10000+parseInt(month)*100+parseInt(day)
  }
  stringToDays(date) {
    if (date) {
      var year = date.substring(0, 4),
      month = date.substring(5, 7),
      day = date.substring(9, 10);
      //console.log(date, parseInt(year)*10000+parseInt(month)*100+parseInt(day))
      return parseInt(year)*10000+parseInt(month)*100+parseInt(day)
    }
    else return 30000000
  }
  setfilter(value, orgData = this.state.orgData) {
    let filter1 = this.state.filterRobot
    let filter2 = this.state.filterCustomer
    let filter3 = this.state.filterCountry
    if (value && value.name === "filter_robot") filter1 = value.value
    if (value && value.target && value.target.name === "filter_customer") filter2 = value.target.value
    if (value && value.name === "filter_country") filter3 = value.value
    //console.log(filter1, filter2)
    let  res = orgData
    let  a = []
    if (filter1 !== "") {
      console.log(filter1)
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => {  if (t.robots.includes(filter1)) a = [...a, t] })
      res = a
    }
    a = []
    if (filter2 !== "") {
      //console.log(filter2)
      const filter = filter2.toLowerCase()
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.company_name.toLowerCase().includes(filter) || t.company_address.toLowerCase().includes(filter) || t.id === -1) a = [...a, t] })
      res = a
    }
    a = []
    if (filter3 !== "" && filter3 !== 0) {
      //console.log(filter3)
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.country === filter3 || t.id === -1) a = [...a, t] })
      res = a
    }
    if (value === null) return res
    else
      this.setState({
        data: res,
        filterRobot: filter1,
        filterCustomer: filter2,
        filterCountry: filter3,
        selected: null,
      })
  }
  back() {
    this.setState({
      showCustomer: -1,
    })
  }

  render() {
    const columns = this.createColumns()
    const supportRight = UserProfile.checkRight("support")
    let filter = false
    const changeColumnsWidth = (width, name) => {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
      GetJsonFormPHP(this.state.setTableUrl + "?table=customer&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
      })
    }
    const selectChange  = value => { this.setfilter(value) }
    let today = new Date();
    let aboLeft14 = new Date();
    aboLeft14.setDate(aboLeft14.getDate() + 30);
    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    })
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : undefined,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      //placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    }
    //{supportRight ? <span> &nbsp; <button onClick={() => {this.setState({ manageFields: true, })}}>{t("show_advanced")}</button></span> : null}
    //{this.state.showCustomer ? <div><div align="center"><button onClick={() => {this.back()}} className="service_save only_support">{t("back")}</button></div><Customer closeDiv={this.back} /></div> :
    return (
      <div>
          <div className="table_buttons">
            {supportRight ? <div className="inline">
              <Select
                options={this.state.country}
                styles={colourStyles}
                isSearchable={true}
                isClearable={true}
                className="chartselect"
                onChange={(value) => {selectChange({name: "filter_country", value: value !== null ? value.value : 0})}}
                placeholder={t('filter_country')}
              />
              {t('filter_customer')}: &nbsp; <input type="text" name="filter_customer" value={this.state.filterCustomer} onChange={selectChange} onKeyDown={selectChange} className="chartselect"/>
              <Select
                options={this.state.robots}
                styles={colourStyles}
                isSearchable={true}
                isClearable={true}
                className="chartselect"
                onChange={(value) => {selectChange({name: "filter_robot", value: value !== null ? value.value : ''})}}
                placeholder={t('customer_robots')}
              />
            </div>: null}
            <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_customers_table", }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div>
          </div>
          <div>
            {this.state.tablePageSize > 0 ?
            <div style={{fontSize: this.state.fontSize+"em"}} className="table_40 inline">
            <ReactTable
              data={this.state.data}
              columns={columns}
              minRows = {0}
              previousText= {'<'}
              nextText= {'>'}
              showPageJump= {true}
              filterable = {filter}
              defaultPageSize = {this.state.tablePageSize}
              pageSizeOptions = {[20, 50, 100, 250]}
              useFlexLayout = {true}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  //onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && denyServiceRight) { this.setState({ selected: rowInfo.index, }) } },
                  style: {
                    background: rowInfo && rowInfo.index === this.state.selected ? 'rgba(234, 234, 255, 1)' : rowInfo.row.is_trader >= 2 ? '#EEEEAA' : rowInfo.row.company_name === "Testaccount" ? '#DDDDDD' : rowInfo.row.is_trader > 0  ? '#EEFFBB' : 'white',
                    color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : this.stringToDays(rowInfo.row.abo_time) < this.dateToDays(today)  ? '#DD0000' : this.stringToDays(rowInfo.row.abo_time) < this.dateToDays(aboLeft14)  ? '#EE8822' :  'black'
                  }
                }
              }}
              getTheadTrProps={(state, column, instance) => {
                return {
                  onClick:  (e) => { if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText) },
                }
              }}
              getProps = {(state, column, instance) => {
                return {
                  onChange:  (e) => { console.log(e.target.name); if (e.target.type !== "number" && e.target.type !== "text" && e.target.name === "") changeColumnsWidth(e.target.value, "_pageSize") },
                }
              }}
            />
            </div>
            : null}
            <div className="table_80 inline"><Customer id = {this.state.showCustomer} /></div>
          </div>
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
      </div>
    )
  }
}
export default Customers;