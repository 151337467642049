import UserProfile from '../../user/UserProfile';

function CallJsonFormPHP(path) {
  const url = process.env.REACT_APP_API_URL +  path
  return fetch(url, {credentials: 'same-origin'}).then(response => {
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response.json()
    })
}
function CallPostJsonFormPHP(path, data) {
  const url = process.env.REACT_APP_API_URL + "/" + path
  return fetch(url, {
    //credentials: 'same-origin',
    method: 'POST',
    Accept: 'application/form-data',
    body: JSON.stringify(data),
  }).then(response => {
    if (!response.ok) {
      throw new Error("HTTP status " + response.status);
    }
    return response.json()
  })
}

// Additional functions to check missing server session (because of timeout?)
function GetJsonFormPHP(path) {
  return CallJsonFormPHP(path).then((json) => {
    if (json.no_login === true) {
      UserProfile.clearProfile()
      window.location.href="/"
    }
    else return json
  })
}
function PostJsonFormPHP(path, data) {
  return CallPostJsonFormPHP(path, data).then((json) => {
    if (json.no_login === true) {
      UserProfile.clearProfile()
      window.location.href="/"
    }
    else return json
  })
}
function PostFileToPHP(path, fileToUpload) {
  const url = process.env.REACT_APP_API_URL + "/" + path
  const formData = new FormData();
  formData.append('fileToUpload', fileToUpload);
  return fetch(url, {
    //credentials: 'same-origin',
    method: 'POST',
    body: formData,
    //Accept: 'multipart/form-data',
    //headers: { 'Content-Type': 'multipart/form-data; ', },
  }).then(response => {console.log(response) })
}

export default GetJsonFormPHP
export {PostJsonFormPHP, PostFileToPHP}