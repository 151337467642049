import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import { Route, Routes, Link, useLocation } from 'react-router-dom'
import Translation from '../user/Translation'
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP"
import UserProfile from '../user/UserProfile'

import HomeStats from './HomeStats.js'
//import Robots from './Robots.js'
//import Aranom from './robots/Aranom.js'
//import Astor from './robots/Astor.js'
//import Athos from './robots/Athos.js'
//import Avenger from './robots/Avenger.js'
import Contact from './Contact.js'

import AnimalGroup from '../charts/AnimalGroup.js'
import AnimalGroupRecipe from '../charts/AnimalGroupRecipe.js'
import Feeds from '../charts/Feeds.js'
import Recipes from '../charts/Recipes.js'
import Tables from '../charts/Tables.js'
import Timechart from '../charts/Timechart.js'
import Energy from '../charts/Energy.js'
import Efficiency from '../charts/Efficiency.js'

import AnimalGroup1 from '../datatables/AnimalGroupList.js'
import BoxList from '../datatables/BoxList.js'
import PushjourneyList from '../datatables/PushjourneyList.js'
import DriveList from '../datatables/DrivesList.js'
//import MilkDailyList from '../datatables/MilkDailyList.js'

import RecipeList from '../datatables/RecipeList.js';
import RobotFeedList from '../datatables/RobotFeedList.js';
import FeedList from '../datatables/FeedList.js';
import SiloList from '../datatables/SiloList.js';
import OrderList from '../datatables/OrderList.js';

import { useNavigate } from "react-router-dom"

function t(field) { return Translation.get(field) }

function SideBar() {
  const navigate = useNavigate()
  if (UserProfile.checkRight("admin")) {
    navigate("/profile/robots")
  }
  const location = useLocation()
  const path = location.pathname.split("/")
  let second = path[2]
  let id= null
  if (path[2] && path[2].indexOf(":") === 0) {
    id = path[2].substring(1, path[2].length)
    window.sessionStorage.setItem("robot_id", id)
  }
  if (path[3] && path[3].indexOf(":") === 0) {
    if (id === null) {
      id = path[3].substring(1, path[3].length)
      window.sessionStorage.setItem("robot_id", id)
    }
    else window.sessionStorage.setItem("robot_name", path[3].substring(1, path[3].length));
  }
  function over(e) {
    switch(e.target.id) {
      case "nav_animalgroup": e.currentTarget.src =  require("../img/navi/mouseover/animalgroup.png"); break
      case "nav_stat_recipe": e.currentTarget.src =  require("../img/navi/mouseover/stat_recipe.png"); break
      case "nav_feeds":       e.currentTarget.src =  require("../img/navi/mouseover/feed.png"); break
      case "nav_tables":      e.currentTarget.src =  require("../img/navi/mouseover/tables.png"); break
      case "nav_energy":      e.currentTarget.src =  require("../img/navi/mouseover/energy.png"); break
      case "nav_timechart":   e.currentTarget.src =  require("../img/navi/mouseover/timechart.png"); break
      case "nav_group_recipe": e.currentTarget.src =  require("../img/navi/mouseover/kuh.png"); break
      case "nav_efficiency":  e.currentTarget.src =  require("../img/navi/mouseover/efficiency.png"); break

      case "nav_animalgroup1": e.currentTarget.src =  require("../img/navi/mouseover/animalgroup.png"); break
      case "nav_box": e.currentTarget.src =  require("../img/navi/mouseover/box.png"); break
      case "nav_route": e.currentTarget.src =  require("../img/navi/mouseover/route.png"); break
      case "nav_drives": e.currentTarget.src =  require("../img/navi/mouseover/drives.png"); break
      case "nav_milk": e.currentTarget.src =  require("../img/navi/mouseover/milk.png"); break

      case "nav_feed":      e.currentTarget.src =  require("../img/navi/mouseover/feed.png"); break;
      case "nav_recipe":    e.currentTarget.src =  require("../img/navi/mouseover/recipe.png"); break;
      case "nav_robotfeed": e.currentTarget.src =  require("../img/navi/mouseover/feeding.png"); break;
      case "nav_order":     e.currentTarget.src =  require("../img/navi/mouseover/order.png"); break;
      case "nav_silo":      e.currentTarget.src =  require("../img/navi/mouseover/silo.png"); break;

      case "nav_contact":     e.currentTarget.src =  require("../img/navi/mouseover/contact.png"); break
      default:
    }
  }
  function out(e) {
    switch(e.target.id) {
      case "nav_animalgroup": if (second === "animalgroup") e.currentTarget.src = require("../img/navi/active/animalgroup.png"); else e.currentTarget.src =  require("../img/navi/animalgroup.png"); break
      case "nav_stat_recipe": if (second === "stat_recipe") e.currentTarget.src = require("../img/navi/active/stat_recipe.png"); else e.currentTarget.src =  require("../img/navi/stat_recipe.png"); break
      case "nav_feeds":       if (second === "feeds") e.currentTarget.src = require("../img/navi/active/feed.png"); else e.currentTarget.src =  require("../img/navi/feed.png"); break
      case "nav_tables":      if (second === "tables") e.currentTarget.src = require("../img/navi/active/tables.png"); else e.currentTarget.src =  require("../img/navi/tables.png"); break
      case "nav_energy":      if (second === "energy") e.currentTarget.src = require("../img/navi/active/energy.png"); else e.currentTarget.src =  require("../img/navi/energy.png"); break
      case "nav_timechart":   if (second === "timechart") e.currentTarget.src = require("../img/navi/active/timechart.png"); else e.currentTarget.src =  require("../img/navi/timechart.png"); break
      case "nav_group_recipe": if (second === "group_recipe") e.currentTarget.src = require("../img/navi/active/kuh.png"); else e.currentTarget.src =  require("../img/navi/kuh.png"); break
      case "nav_efficiency":  if (second === "efficiency") e.currentTarget.src = require("../img/navi/active/efficiency.png"); else e.currentTarget.src =  require("../img/navi/efficiency.png"); break

      case "nav_animalgroup1": if (second === "animalgroup1") e.currentTarget.src = require("../img/navi/active/animalgroup.png"); else e.currentTarget.src =  require("../img/navi/animalgroup.png"); break
      case "nav_box":         if (second === "boxes") e.currentTarget.src = require("../img/navi/active/box.png"); else e.currentTarget.src =  require("../img/navi/box.png"); break
      case "nav_route":       if (second === "routes") e.currentTarget.src = require("../img/navi/active/route.png"); else e.currentTarget.src =  require("../img/navi/route.png"); break
      case "nav_drives":      if (second === "drives") e.currentTarget.src = require("../img/navi/active/drives.png"); else e.currentTarget.src =  require("../img/navi/drives.png"); break
      case "nav_milk":        if (second === "milk") e.currentTarget.src = require("../img/navi/active/milk.png"); else e.currentTarget.src =  require("../img/navi/milk.png"); break

      case "nav_feed":      if (second === "feed") e.currentTarget.src = require("../img/navi/active/feed.png"); else e.currentTarget.src =  require("../img/navi/feed.png"); break
      case "nav_recipe":    if (second === "recipe") e.currentTarget.src = require("../img/navi/active/recipe.png"); else e.currentTarget.src =  require("../img/navi/recipe.png"); break
      case "nav_robotfeed": if (second === "robotfeed") e.currentTarget.src = require("../img/navi/active/feeding.png"); else e.currentTarget.src =  require("../img/navi/feeding.png"); break
      case "nav_order":     if (second === "orders") e.currentTarget.src = require("../img/navi/active/order.png"); else e.currentTarget.src =  require("../img/navi/order.png"); break
      case "nav_silo":      if (second === "silo") e.currentTarget.src = require("../img/navi/active/silo.png"); else e.currentTarget.src =  require("../img/navi/silo.png"); break

      case "nav_contact": if (second === "contact") e.currentTarget.src = require("../img/navi/active/contact.png"); else e.currentTarget.src =  require("../img/navi/contact.png"); break
      default:
    }
  }
  let add = ""
  if (path[1] !== "home") add = "home/"
  /*<Link to={add + "Aranom"}><img id="nav_Aranom" src={require("../img/robots/HC_Aranom_r.png")} alt="Aranom" title="Aranom" className="robot" /></Link>
  <Link to={add + "Astor"}><img id="nav_Astor" src={require("../img/robots/HC_Astor_r.png")} alt="Astor" title="Astor" className="robot" /></Link>
  <Link to={add + "Athos"}><img id="nav_Athos" src={require("../img/robots/HC_Athos_r.png")} alt="Athos" title="Athos" className="robot" /></Link>
  <Link to={add + "Avenger"}><img id="nav_Avenger" src={require("../img/robots/HC_Avenger_r.png")} alt="Avenger" title="Avenger" className="robot" /></Link>*/
  return (
    <div className="nav_left_main">
      <Link to="/"><img id="nav_home" src={require("../img/navi/home.png")} alt={t('nav_home')}  title={t('nav_home')} onMouseOver={over} onMouseOut={out}/></Link>
      {UserProfile.getFavorites('A', 0) ? <Link to="feeds"><img id="nav_feeds" src={second === "feeds" ? require("../img/navi/active/feed.png") : require("../img/navi/feed.png")} alt={t('nav_feeds')} title={t('nav_feeds')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('A', 1) ? <Link to="animalgroup"><img id="nav_animalgroup" src={second === "animalgroup" ? require("../img/navi/active/animalgroup.png") : require("../img/navi/animalgroup.png")} alt={t('nav_animalgroup')} title={t('nav_animalgroup')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('A', 2) ? <Link to="group_recipe"><img id="nav_group_recipe" src={second === "group_recipe" ? require("../img/navi/active/kuh.png") : require("../img/navi/kuh.png")} alt={t('nav_group_recipe')} title={t('nav_group_recipe')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('A', 3) ? <Link to="stat_recipe"><img id="nav_stat_recipe" src={second === "stat_recipe" ? require("../img/navi/active/stat_recipe.png") : require("../img/navi/stat_recipe.png")} alt={t('nav_stat_recipe')} title={t('nav_stat_recipe')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('A', 4) ? <Link to="timechart"><img id="nav_timechart" src={second === "timechart" ? require("../img/navi/active/timechart.png") : require("../img/navi/timechart.png")} alt={t('nav_timechart')} title={t('nav_timechart')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('A', 5) ? <Link to="energy"><img id="nav_energy" src={second === "energy" ? require("../img/navi/active/energy.png") : require("../img/navi/energy.png")} alt={t('nav_energy')} title={t('nav_energy')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('A', 6) ? <Link to="efficiency"><img id="nav_efficiency" src={second === "efficiency" ? require("../img/navi/active/efficiency.png") : require("../img/navi/efficiency.png")} alt={t('nav_efficiency')} title={t('nav_efficiency')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('A', 7) ? <Link to="tables"><img id="nav_tables" src={second === "tables" ? require("../img/navi/active/tables.png") : require("../img/navi/tables.png")} alt={t('nav_tables')} title={t('nav_tables')} onMouseOver={over} onMouseOut={out}/></Link> : null}

      {UserProfile.getFavorites('FG', 0) ? <Link to="drives"><img id="nav_drives" src={second === "drives" ? require("../img/navi/active/drives.png") : require("../img/navi/drives.png")} alt={t('nav_drives')} title={t('nav_drives')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('FG', 1) ? <Link to="animalgroup1"><img id="nav_animalgroup1" src={second === "animalgroup1" ? require("../img/navi/active/animalgroup.png") : require("../img/navi/animalgroup.png")} alt={t('nav_animalgroup')} title={t('nav_animalgroup')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('FG', 2) ? <Link to="boxes"><img id="nav_box" src={second === "boxes" ? require("../img/navi/active/box.png") : require("../img/navi/box.png")} alt={t('nav_boxes')} title={t('nav_boxes')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('FG', 3) ? <Link to="routes"><img id="nav_route" src={second === "routes" ? require("../img/navi/active/route.png") : require("../img/navi/route.png")} alt={t('nav_routes')} title={t('nav_routes')} onMouseOver={over} onMouseOut={out}/></Link> : null}

      {UserProfile.getFavorites('F', 0) ? <Link to="recipe1"><img id="nav_recipe" src={second === "recipe1" ? require("../img/navi/active/recipe.png") : require("../img/navi/recipe.png")} alt={t('nav_recipe')} title={t('nav_recipe')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('F', 1) ? <Link to="robotfeed"><img id="nav_robotfeed" src={second === "robotfeed" ? require("../img/navi/active/feeding.png") : require("../img/navi/feeding.png")} alt={t('nav_robotfeed')} title={t('nav_robotfeed')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('F', 2) ? <Link to="feed"><img id="nav_feed" src={second === "feed" ? require("../img/navi/active/feed.png") : require("../img/navi/feed.png")} alt={t('nav_feed')} title={t('nav_feed')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('F', 3) ? <Link to="silo"><img id="nav_silo" src={second === "silo" ? require("../img/navi/active/silo.png") : require("../img/navi/silo.png")} alt={t('nav_silo')} title={t('nav_silo')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      {UserProfile.getFavorites('F', 4) ? <Link to="orders"><img id="nav_order" src={second === "orders" ? require("../img/navi/active/order.png") : require("../img/navi/order.png")} alt={t('nav_orders')} title={t('nav_orders')} onMouseOver={over} onMouseOut={out}/></Link> : null}

      <Link to={add + "contact"} className="bottom"><img className="bottom" id="nav_contact" src={second === "contact" ? require("../img/navi/active/contact.png") : require("../img/navi/contact.png")} alt={t('nav_contact')} title={t('nav_contact')} onMouseOver={over} onMouseOut={out}/></Link>
    </div>
  )
}

class Home extends Component {
  constructor() {
    super();
    this.state={
      geturl: "/robots/get_robots.php?main=1",
      data: [],
      robottypes: [],
    }
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({
        data: result.data,
        robottypes: result.robottypes,
      })
      let favoritesA = [false, false, false, false, false, false, false, false, ]
      let favoritesFG = [false, false, false, false, ]
      let favoritesF = [false, false, false, false, false, ]
      if (result.pages) {
        favoritesA = result.pages.A
        favoritesF = result.pages.F
        favoritesFG = result.pages.FG
      }
      UserProfile.setFavorites(favoritesA, favoritesF, favoritesFG)
    })
  }
  setNewRobot(id, name) {
    window.sessionStorage.setItem("robot_id", id)
    window.sessionStorage.setItem("robot_name", name)
  }

  render() {
    //<Route path="Aranom" element={<Aranom />} />
    //<Route path="Astor" element={<Astor />} />
    //<Route path="Athos" element={<Athos />} />
    //<Route path="Avenger" element={<Avenger />} />
    return (
      <div>
        <div className="nav2">
          <nav id="navigation_2">< SideBar /></nav>
        </div>
        <div className="content">
        <div className="body">
        <div className="tablebody">
          <Routes>
            <Route path="/*" element={<HomeStats />} />
            <Route path="home" element={<HomeStats />} />
            <Route path="contact" element={<Contact />}/>

            <Route path="animalgroup/*" element={<AnimalGroup />} />
            <Route path="group_recipe/*" element={<AnimalGroupRecipe />} />
            <Route path="feeds/*" element={<Feeds />} />
            <Route path="stat_recipe/*" element={<Recipes />} />
            <Route path="timechart" element={<Timechart />} />
            <Route path="energy" element={<Energy />} />
            <Route path="efficiency" element={<Efficiency />} />
            <Route path="tables" element={<Tables />} />

            <Route path="animalgroup1/*" element={<AnimalGroup1 />} />
            <Route path="routes" element={<PushjourneyList />} />
            <Route path="drives" element={<DriveList />} />
            <Route path="boxes" element={<BoxList />} />

            <Route path="recipe1" element={<RecipeList />} />
            <Route path="robotfeed" element={<RobotFeedList />} />
            <Route path="feed" element={<FeedList />} />
            <Route path="silo" element={<SiloList />} />
            <Route path="orders" element={<OrderList />} />

          </Routes>
        </div>
        </div>
        </div>
      </div>
    )
  }
}

export default Home