import React, { Component } from 'react'
import { Route, Routes, Link, useLocation } from 'react-router-dom'
import Translation from '../user/Translation'
import UserProfile from '../user/UserProfile'

import UserList from '../datatables/UserList.js'
import Customers from '../datatables/CustomerList.js'
import RobotList from '../datatables/RobotList.js'
import ErrorList from '../datatables/ErrorList.js'
import Service from '../datatables/Service.js'
import RobotTypes from '../datatables/RobotTypes.js'
import Translations from '../datatables/Translations.js'
import ErrorTranslations from '../datatables/TranslationsError.js'
import Downloads from './Downloads.js'
import ServiceSheet from './Serviceprotokoll.js'
import CustomerSupport from './CustomerSupport.js'
import Contact from './Contact.js'

function t(field) { return Translation.get(field) }


function SideBar() {
  const location = useLocation()
  let first = location.pathname.substring(1, location.pathname.length)
  let second = null
  if (first.indexOf("/") > 0) {
    second = first.substring(first.indexOf("/")+1, first.length+1)
    first = first.substring(0, first.indexOf("/"))
  }
  if (UserProfile.checkRight("addcust") && !first) first = "customer"
  if (!second) second = first
  function over(e) {
    switch(e.target.id) {
      case "nav_robots":          e.currentTarget.src =  require("../img/navi/mouseover/robots.png"); break
      case "nav_error":           e.currentTarget.src =  require("../img/navi/mouseover/error.png"); break
      case "nav_user":            e.currentTarget.src =  require("../img/navi/mouseover/user.png"); break
      case "nav_customer":        e.currentTarget.src =  require("../img/navi/mouseover/customer.png"); break
      case "nav_robottypes":      e.currentTarget.src =  require("../img/navi/mouseover/robottyp.png"); break
      case "nav_translation":     e.currentTarget.src =  require("../img/navi/mouseover/translation.png"); break
      case "nav_errortranslation":e.currentTarget.src =  require("../img/navi/mouseover/errortranslation.png"); break
      case "nav_download":        e.currentTarget.src =  require("../img/navi/mouseover/download.png"); break
      case "nav_contact":         e.currentTarget.src =  require("../img/navi/mouseover/contact.png"); break
      case "nav_service":         e.currentTarget.src =  require("../img/navi/mouseover/service.png"); break
      case "nav_service_sheet":   e.currentTarget.src =  require("../img/navi/mouseover/maintenence.png"); break
      case "nav_support":         e.currentTarget.src =  require("../img/navi/mouseover/support.png"); break
      default:
    }
  }
  function out(e) {
    switch(e.target.id) {
      case "nav_robots":          if (second === "robots") e.currentTarget.src = require("../img/navi/active/robots.png"); else e.currentTarget.src =  require("../img/navi/robots.png"); break
      case "nav_error":           if (second === "error") e.currentTarget.src = require("../img/navi/active/error.png"); else e.currentTarget.src =  require("../img/navi/error.png"); break
      case "nav_user":            if (second === "user") e.currentTarget.src = require("../img/navi/active/user.png"); else e.currentTarget.src =  require("../img/navi/user.png"); break
      case "nav_customer":        if (second === "customer") e.currentTarget.src = require("../img/navi/active/customer.png"); else e.currentTarget.src =  require("../img/navi/customer.png"); break
      case "nav_robottypes":      if (second === "robottypes") e.currentTarget.src = require("../img/navi/active/robottyp.png"); else e.currentTarget.src =  require("../img/navi/robottyp.png"); break
      case "nav_translation":     if (second === "translation") e.currentTarget.src = require("../img/navi/active/translation.png"); else e.currentTarget.src =  require("../img/navi/translation.png"); break
      case "nav_errortranslation":if (second === "errortranslation") e.currentTarget.src = require("../img/navi/active/errortranslation.png"); else e.currentTarget.src =  require("../img/navi/errortranslation.png"); break
      case "nav_download":        if (second === "download") e.currentTarget.src = require("../img/navi/active/download.png"); else e.currentTarget.src =  require("../img/navi/download.png"); break
      case "nav_contact":         if (second === "contact") e.currentTarget.src = require("../img/navi/active/contact.png"); else e.currentTarget.src =  require("../img/navi/contact.png"); break
      case "nav_service":         if (second === "service") e.currentTarget.src = require("../img/navi/active/service.png"); else e.currentTarget.src =  require("../img/navi/service.png"); break
      case "nav_service_sheet":   if (second === "service_sheet") e.currentTarget.src = require("../img/navi/active/maintenence.png"); else e.currentTarget.src =  require("../img/navi/maintenence.png"); break
      case "nav_support":         if (second === "support") e.currentTarget.src = require("../img/navi/active/support.png"); else e.currentTarget.src =  require("../img/navi/support.png"); break
      default:
    }
  }  //UserProfile.checkRight("support")
  //<Link to="service_sheet"><img id="nav_service_sheet" src={second === "service_sheet" ? require("../img/navi/active/maintenence.png") :  require("../img/navi/maintenence.png")} alt={t('nav_service_sheet')} title={t('nav_service_sheet')} onMouseOver={over} onMouseOut={out}/></Link>
  return (
    <div className={UserProfile.getUserLevel() === 1 ? " nav_left_main_su" : "nav_left_main"}>
      <Link to="robots"><img id="nav_robots" src={second === "robots" ? require("../img/navi/active/robots.png") : require("../img/navi/robots.png")} alt={t('nav_robots')} title={t('nav_robots')} onMouseOver={over} onMouseOut={out}/></Link>
      { UserProfile.getUserLevel() !== 6 ?<Link to="error"><img id="nav_error" src={second === "error" ? require("../img/navi/active/error.png") : require("../img/navi/error.png")} alt={t('nav_error')} title={t('nav_error')} onMouseOver={over} onMouseOut={out}/></Link> : null }
      <Link to="user"><img id="nav_user" src={second === "user" ? require("../img/navi/active/user.png") : require("../img/navi/user.png")} alt={t('nav_user')} title={t('nav_user')} onMouseOver={over} onMouseOut={out}/></Link>
      { UserProfile.getUserLevel() !== 6 ?<Link to="customer"><img id="nav_customer" src={second === "customer" ? require("../img/navi/active/customer.png") : require("../img/navi/customer.png")} alt={t('nav_customer')} title={t('nav_customer')} onMouseOver={over} onMouseOut={out}/></Link> : null }
      { UserProfile.getUserLevel() !== 6 ?<Link to="service"><img id="nav_service" src={second === "service" ? require("../img/navi/active/service.png") : require("../img/navi/service.png")} alt={t('nav_service')} title={t('nav_service')} onMouseOver={over} onMouseOut={out}/></Link>: null }

      { UserProfile.getUserLevel() <= 2 ?
        <div>
          <Link to="robottypes"><img id="nav_robottypes" src={second === "robottypes" ? require("../img/navi/active/robottyp.png") : require("../img/navi/robottyp.png")} alt={t('nav_robottypes')} title={t('nav_robottypes')} onMouseOver={over} onMouseOut={out}/></Link>
          <Link to="errortranslation"><img id="nav_errortranslation" src={second === "errortranslation" ? require("../img/navi/active/errortranslation.png") :  require("../img/navi/errortranslation.png")} alt={t('nav_errortranslation')} title={t('nav_errortranslation')} onMouseOver={over} onMouseOut={out}/></Link>
          <Link to="download"><img id="nav_download" src={second === "download" ? require("../img/navi/active/download.png") : require("../img/navi/download.png")} alt={t('nav_download')} title={t('nav_download')} onMouseOver={over} onMouseOut={out}/></Link>
        </div>
      : null }
      {UserProfile.getUserLevel() === 1 ?
        <div>
          <Link to="support"><img id="nav_support" src={second === "support" ? require("../img/navi/active/support.png") :  require("../img/navi/support.png")} alt={t('nav_support')} title={t('nav_support')} onMouseOver={over} onMouseOut={out}/></Link>
          <Link to="translation"><img id="nav_translation" src={second === "translation" ? require("../img/navi/active/translation.png") :  require("../img/navi/translation.png")} alt={t('nav_translation')} title={t('nav_translation')} onMouseOver={over} onMouseOut={out}/></Link>
        </div>
      : null
      }
      <Link to="contact" className="bottom"><img className="bottom" id="nav_contact" src={second === "contact" ? require("../img/navi/active/contact.png") : require("../img/navi/contact.png")} alt={t('nav_contact')} title={t('nav_contact')} onMouseOver={over} onMouseOut={out}/></Link>
    </div>
  )
}

class Profile extends Component {
  render()
  {
    return (
      <div>
        <div className="nav2">
          <nav id="navigation_2"> <SideBar /></nav>
        </div>
        <div className="content">
          <div className="body">
            <div className="tablebody">
              <Routes>
                <Route path="" element={<Customers />} />
                <Route path="user" element={<UserList />} />
                <Route path="customer" element={<Customers />} />
                <Route path="robots" element={<RobotList />} />
                <Route path="error" element={<ErrorList />} />
                <Route path="robottypes" element={<RobotTypes />} />
                <Route path="service" element={<Service />} />
                <Route path="translation" element={<Translations />} />
                <Route path="errortranslation" element={<ErrorTranslations />} />
                <Route path="download" element={<Downloads />} />
                <Route path="service_sheet" element={<ServiceSheet />} />
                <Route path="support" element={<CustomerSupport />} />
                <Route path="contact" element={<Contact />}/>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Profile