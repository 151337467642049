import React, { Component } from 'react'
import GetJsonFormPHP, { PostJsonFormPHP } from "./GetJsonFormPHP"
import UserProfile from '../../user/UserProfile'
//import Select from 'react-select'
import TextField from '@mui/material/TextField';
import dateFormat from 'dateformat'
import Translation from '../../user/Translation'
import NumberFormat from 'react-number-format'
//import Select from 'react-select'

import imgTrash from '../img/trash.png'
import imgSave from '../img/save-as.png'
import imgSaveDisabled from '../img/save-disabled.png'
import imgEdit from '../img/edit.png'
import imgCancel from '../img/cancel.png'
import imgAdd from '../img/add.png'
import imgInstall from '../img/install.png'
import imgArchiv from '../img/archiv.png'
import imgUpload from '../img/upload.png'
import imgFalse from '../img/false2.png'
import imgTrue from '../img/true.gif'
//import imgUpdate from '../img/install_mo.png'

function t(field) { return Translation.get(field) }

class TableFunctions extends Component {
  constructor() {
    super()
    this.state={
      saveShowFields: null,
      fieldError: false,
    }
    this.insertRow = this.insertRow.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.updateRobot = this.updateRobot.bind(this)
    //this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClearDate = this.handleClearDate.bind(this)
    this.textCell = this.textCell.bind(this)
    this.textareaCell = this.textareaCell.bind(this)
    this.dateCell = this.dateCell.bind(this)
    this.selectCell = this.selectCell.bind(this)
    this.edit = this.edit.bind(this)
  }
  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }
  getColor(id) {
    switch (id) {
      case "cellAdmin": return '#F9EEEE'
      case "textAdmin": return '#999999'
      case "cellAdvanced": return '#F9F5E0'
      case "readOnly": return '#F5F5FA'
      case "notused": return '#FFD5DA'
      case "production": return '#339300'
      case "only_support": return '#A0C8FF'
      case "selected": return '#999999'
      default: return null
    }
  }
  getRobotColor(id) {
    const backColors = ['white', '#C0D4EE', '#D0E4FE', '#FFEAF5', '#FFD5E5', '#9FDCDF', '#FFCCCC', 'SeaShell', '#F5E5E0', '#E5CECC', '#F8C5D5', 'LightYellow', '#F5EECC', 'Snow', 'Snow']
    return backColors[id]
  }
  getStatusBackColor(id, readOnly = false){
    const backColors = ['#FFF5F5', '#FFF5F5', '#FFF5F5', '#F5F5FF', '#F5FFF5', 'lightgrey', '#b3b3b3', '#FFE5E5', 'lightgrey']
    const backColorsReadOnly = ['#A5C4D9', '#F5E5F0', '#F5E5F0', '#EFEFFF', '#F5F5FA', 'lightgrey', '#b3b3b3', '#FFE5E5', 'lightgrey']
    if (!readOnly) return backColors[id]
    else return backColorsReadOnly[id]
  }
  getStatusColor(id){
    const backColors = ["#999999", "#999999", "#666666", "#0033AA", "#008800", "#666666", "grey",  "red", "#AA0099", "#CA7A16", "#DD9999"]
    return backColors[id]
  }

  // isInt: 0 .. text;  1 .. Integer (0 is empty);  2 .. decimal;  4 ... IP;                    10: integer (0 is 0)???
  addNumberCell(header, accessor, isInt = 1, min = null, max = null, cellWidth = 0, show = true, addText = "", align = null, cellColor = null, textColor= null, edit = true, filterable = false, decimals = -1, mouseoverText = null, need = false, editOnNew = false) {
    return this.addTextCellPrivate(header, accessor, isInt, cellWidth, show, addText, align, cellColor, textColor, edit, filterable, decimals, mouseoverText, need, editOnNew, min, max)
  }
  //isInt: 0 .. text;  1 .. Interger (0 is empty);  2 .. decimal;  10: integer (0 is 0)
  addTextCell(header, accessor, isInt = 0, cellWidth = 0, show = true, addText = "", align = null, cellColor = null, textColor= null, edit = true, filterable = false, decimals = -1, mouseoverText = null, need = false, editOnNew = false) {
    return this.addTextCellPrivate(header, accessor, isInt, cellWidth, show, addText, align, cellColor, textColor, edit, filterable, decimals, mouseoverText, need, editOnNew, null, null)
  }
  addTextCellPrivate(header, accessor, isInt, cellWidth, show, addText, align, cellColor, textColor, edit, filterable, decimals, mouseoverText, need, editOnNew, min, max) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    if (isInt > 0 && align === null) align = "right"
    let headertext = t(header)
    if (header.substring(0, 1) === "§") headertext = header.substring(1, 1000)
    let result = {
      Header: () =>  <span dangerouslySetInnerHTML={{__html: headertext}}></span>,
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row,}) => { return this.textCell(initialValue, id , row, isInt, addText, edit || (editOnNew && row.id <= 0), decimals, mouseoverText, min, max, need) },
      getProps: (state, rowInfo) => ({ style: { textAlign: align, color: textColor === "row" ? rowInfo.row.color : textColor, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : cellColor === 'readonly' ? this.getStatusBackColor(rowInfo.row.status, true) : cellColor } }),
      filterable: filterable,
    }
    if (cellWidth > 0) result.width = cellWidth*em
    return result
  }
  addTextAreaCell(header, accessor, cellWidth = 0, rowWidth = 3, show = true, align = null, cellColor = null, textColor= null, edit = true, filterable = false, fontSize = 1) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let headertext = t(header)
    if (header.substring(0, 1) === "§") headertext = header.substring(1, 1000)
    let result = {
      Header: headertext,
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row,}) => { return this.textareaCell(initialValue, id , row, rowWidth, edit) },
      getProps: (state, rowInfo) => ({ style: { textAlign: align, color: textColor, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : cellColor, fontSize: fontSize*0.8*em} }),
      filterable: filterable,
    }
    if (cellWidth > 0) result.width = cellWidth*em
    return result
  }
  addSelectCell(header, accessor, options, cellwidth = 0, show = true, align = null, cellColor = null, addempty = false, edit = true, filterable = false, value = false, mouseoverOptions = null, ratio = false, editOnNew = false, emptyVal = '', setLarge = false) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    //let optionCount = 0
    //if (Array.isArray(options)) Object.entries(options).map((t,k) => { optionCount += 1; return null })
    //console.log(options, typeof options, optionCount)
    let result = {
      Header: t(header),
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row}) => {
        if (setLarge) return this.selectCellLarge(id , row, options[initialValue], options, addempty, edit || (editOnNew && row.id <= 0), value ? initialValue : null, mouseoverOptions, ratio, emptyVal)
        else return this.selectCell(id , row, initialValue, options, addempty, edit || (editOnNew && row.id <= 0), value ? initialValue : null, mouseoverOptions, ratio, emptyVal)
      },
      getProps: (state, rowInfo) => ({ style: { textAlign: align, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : cellColor === 'readonly' ? this.getStatusBackColor(rowInfo.row.status, true) : cellColor } }),
      filterable: filterable,
    }
    if (cellwidth > 0) result.width = cellwidth*em
    return result
  }
  addDateCell(header, accessor, showTime = false, cellwidth = 0, show = true, align = null, cellColor = null, textColor= null, edit = true, filterable = false, clear = false) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let format = t('dateformat')
    if (showTime) format = format + " HH:MM"
    let result = {
      Header: t(header),
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row,}) => {
        //if (initialValue) {
          if (edit) return this.dateCell(initialValue, id , row, edit, showTime, clear);
          else return (<span>{dateFormat(initialValue, format)}</span>)
        //}
        //else return initialValue
      },
      getProps: (state, rowInfo) => ({ style: { textAlign: align, color: textColor, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : cellColor === 'readonly' ? this.getStatusBackColor(rowInfo.row.status, true) : cellColor } }),
      filterable: filterable,
    }
    if (cellwidth > 0) result.width = cellwidth*em
    return result
  }
  addCheckboxCell(header, accessor, cellwidth = 0, show = true, images = ["ok", "x"], choises = [t("yes"), t("no")], cellColor = null, edit = true, filterable = false) { // nicht benutzt, da unvollständig / nicht vollständig durchdacht
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let result = {
      Header: t(header),
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row,}) => { return this.checkboxCell(initialValue, id , row, images, choises, edit) },
      getProps: (state, rowInfo) => ({ style: { backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusgetStatusBackColorColor(rowInfo.row.status) : cellColor } }),
      filterable: filterable,
    }
    if (cellwidth > 0) result.width = cellwidth*em
    return result
  }

  insertRow(showFields = null) {
    let insert = false
    if (this.state.data.length === 0) insert = true
    else if (this.state.data[0].id !== -1) insert = true
    if (insert) {
      this.setState( {
        selected: 0,
        saveShowFields: showFields,
        data: [this.state.empty, ...this.state.data]
      })
      if (this.state.orgData) {
        this.setState( {
          selected: 0,
          orgData: [this.state.empty, ...this.state.orgData]
        })
      }
    }
    else {
      this.setState( {
        selected: 0,
      })
    }
  }
  handleSubmit(event) {
    if (!this.state.fieldError) {
      let row = event
      if (row.id === -1 || row.id === "-1" || row.org_id === "") row.action="INSERT"
      else row.action="UPDATE"
      //console.log("saveurl", this.state.saveurl, row)
      //console.log(this.state.data)
      for (let key in row) { if (key.substring(0,1) === "_") delete row[key] }
      PostJsonFormPHP(this.state.saveurl, row).then((result) => {
        if (result.error) {
          this.setState({
            confirmVisible: true,
            title: t("error"),
            message: t(result.error),
            choise: false,
            selected: null,
          })
          this.reload()
        }
        else {
          if (result.set_new_font) UserProfile.setFont(result.set_new_font)
          if (result.new_id) this.reload(result.new_id)
          else this.reload()
        }
      })
      this.setState({
        selected: null,
      })
    }
  }
  handleDelete = (confirm) =>{
    this.setState({confirmVisible: false})
    if (confirm === true && this.state.choise) {
      const row = this.state.data[this.state.selected]
      let delimiter = "?"
      if (this.state.saveurl.includes('?')) delimiter = "&"
      if (row) GetJsonFormPHP(this.state.saveurl + delimiter +"delID=" + row.id).then((result) => {
        if (result.new_id) this.reload(result.new_id)
        else this.reload()
      })
    }
    this.setState({
      selected: null,
    })
  }
  updateRobot(id) {
    if (this.state.updateurl) {
      GetJsonFormPHP(this.state.updateurl + "?id=" + id).then((result) => {
        this.reload()
      });
    }
  }

  recalc_fields(org, value, index) { // dummy function which can be overwritten by calling script to change dependency fields
  }
  checkInputChars(value, inputType) { // 1 .. integer;   2 .. number;  3.. time (H:i);   4 .. IP-Address;
    const re1 = /^[-0-9\b]+$/;        // integer
    const re2 = /^[0-9.\b-]+$/;     // number / IP-Address
    const re3 = /^[0-9:\b]+$/;      // time
    let ok = true;
    if (inputType) {
      if ((inputType === 1 || inputType === 10) && !(value.target.value === '' || re1.test(value.target.value))) ok = false
      if (inputType === 2) { // Number
        if(!(value.target.value === '' || re2.test(value.target.value))) ok = false
        if (isNaN(value.target.value) && value.target.value !== "-" && value.target.value !== "+" && value.target.value !== ".") return false
        //if (value.target.value.split(".").length - 1 > 1) ok = false;  // max. 1x "."
        //if (value.target.value.split("-").length - 1 > 1) ok = false;  // max. 1x "."
        //if (value.target.value === '-.') ok = false;
      }
      if (inputType === 3) {  // time (H:i)
        if (!(value.target.value === '' || re3.test(value.target.value))) ok = false
        if (value.target.value.split(":").length - 1 > 1) ok = false;  // max. 1x ":"
      }
      if (inputType === 4 && !(value.target.value === '' || re2.test(value.target.value))) ok = false
    }
    return ok
  }
  handleInputChange(index, target, value, inputType, min = null, max = null, decimals = null) {
    let val = value.target.value
    let type = value.target.type
    /*console.log("handleInputChange", index, target, value, typeof value.target)
    if (value === null || typeof value.target === 'undefined') {
      val = value.value
      type = "select"
      console.log("select insert", value)
    }
    else {
      val = value.target.value
      type = value.target.type
    }*/
    if (this.checkInputChars(value, inputType)) {
      let orgVal = null
      let data = this.state.data
      let row = data[index]
      let newVal = val
      let error = false
      //if (min !== null && (inputType === 1 || inputType === 2) && value.target.value < min) newVal = min
      //if (max !== null && (inputType === 1 || inputType === 2) && value.target.value > max) newVal = max
      if (min !== null && (inputType === 1 || inputType === 2) && val < min) error = true
      if (max !== null && (inputType === 1 || inputType === 2) && val > max) error = true
      if (inputType === 2 && decimals !== null && decimals > 0 && newVal !== "-" && newVal !== "+" && newVal !== ".") {
        newVal = newVal*10**decimals
        if (newVal < 0) newVal = Math.ceil(newVal)
        else  newVal = Math.floor(newVal)
        newVal = newVal/10**decimals
        //console.log(target, newVal, decimals)
        if (parseFloat(val) === parseFloat(newVal)) newVal = val
      }
      if (inputType === 3) {
        let time = newVal.split(":");
        if (time[0] > 24) newVal = "24:"+time[1]
        if (time[1] > 59) newVal = time[0]+":59"
      }
      if (type === "checkbox") for (let key in row) { if (key === target) row[key] = row[key] === 1 ? 0 : 1 } // Checkbox switches always between 0 and 1
      else for (let key in row) { if (key === target) { orgVal=row[key]; row[key] = newVal } }
      //console.log(target, newVal, row)
      //console.log(index, target, value.target.type, value.target.value, row)
      data[index] = row
      //console.log("change to ", index, row)
      this.setState({
        data: data,
        fieldError: error,
      })
      this.recalc_fields(orgVal, value, index)
    }
  }
  handleDatalistChange(index, target, value, options) {
    let val = value.target.value
    //console.log("ListChange", index, target, value.target.value)
    let id = -100

    Object.entries(options).map((t,k) => { if (val ===t[1]) id = t[0]; return null})
      console.log(id)

      //let orgVal = null
      let data = this.state.data
      let row = data[index]
      let newVal = val

      for (let key in row) { if (key === target) { row[key] = newVal } }
      //data[index] = row
      this.setState({
      //  data: data,
        //fieldError: error,
      })
  }
  handleClearDate(index, target) {
    let data = this.state.data
    let row = data[index]
    for (let key in row) { if (key === target) { row[key] = '' } }
    data[index] = row
    this.setState({
      data: data,
    })
  }
  textCell(initialValue, id, row, inputType = null, add = null, edit = true, decimals, mouseoverText = null, min = null, max = null, need = null) {
    let className = inputType ? "right_align" : ""
    if (need) className += " need"
    //console.log(this.state.data, row)
    const inputChange = value => { this.handleInputChange(row._index, id, value, inputType, min , max, decimals) }
    const _handleKeyDown = e => { if (e.key === 'Enter') this.handleSubmit(row) }
    //if (this.state.fieldError) className += " error"
    let mouseover = initialValue
    if (mouseoverText) mouseover = mouseoverText
    if (min || max) mouseover = min + " - " + max
    if (edit && this.state.selected === row._index) {
      //if (id ==="animals" && row.new_animals) {console.log(id, row); id ="new_animals"; initialValue = row.new_animals }
      return (
        <span><input type="text" id={id} name={id} title={mouseover} value={initialValue} onChange={inputChange} onKeyDown={_handleKeyDown} className={className}/> {need} {this.state.fieldError && max !== null ? <div className="error">{min + " - " + max}</div> : null}</span>
      )
    }
    else {
      if (add && (add === "€" || add === "$")) return (<span title={mouseover}><NumberFormat value={initialValue} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} suffix={' ' + add} /></span>)
      else if (inputType === 1 && (initialValue >= 1000 || initialValue <= 1000)) {
        return (<span title={mouseover}><NumberFormat value={initialValue} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator=" " suffix={''} /> {add ? <span dangerouslySetInnerHTML={{__html: add}}></span>: null}</span>)
      }
      else if (inputType === 2) {
        if (decimals > -1) return (<span title={mouseover}><NumberFormat value={initialValue} displayType={'text'} decimalScale={decimals} fixedDecimalScale={true} thousandSeparator=" " suffix={''} /> {add ? <span dangerouslySetInnerHTML={{__html: add}}></span>: null}</span>)
        else return (<span title={mouseover}><NumberFormat value={initialValue} displayType={'text'} fixedDecimalScale={true} thousandSeparator=" " suffix={''} /> {add ? <span dangerouslySetInnerHTML={{__html: add}}></span>: null}</span>)
      }
      else {
        if (inputType === 10) return (<span title={mouseover}><NumberFormat value={initialValue} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} suffix={' ' + add} /></span>)
        if (this.isString(initialValue)) {
          if (id === "email") return (<span title={mouseover}><span dangerouslySetInnerHTML={{__html: '<a href="mailto:' + initialValue + '">' + initialValue + "</a>"}}></span> {add ? <span dangerouslySetInnerHTML={{__html: add}}></span>: null}</span>)
          return (<span title={mouseover}><span dangerouslySetInnerHTML={{__html: initialValue}}></span> {add ? <span dangerouslySetInnerHTML={{__html: add}}></span>: null}</span>)
        }
        else if (initialValue) return (<span title={mouseover}>{ initialValue} {add ? <span dangerouslySetInnerHTML={{__html: add}}></span>: null}</span>)
        else return null
      }
    }
  }
  textareaCell(initialValue, id , row, rows = 3, edit=true) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (typeof initialValue === 'undefined') initialValue = ""
    if (initialValue.length > 200) rows = Math.round(initialValue.length / 70)
    if (edit && this.state.selected === row._index) {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
      //console.log(rows, rows*em*1.2)
      return (
        <textarea name={id} rows={3} cols={40} style={{minHeight: rows*em*1.2}} onChange={inputChange} value={initialValue} />
      )
    }
    else {
      if (typeof initialValue === "string") return (<span title={initialValue} ><span dangerouslySetInnerHTML={{__html: initialValue.replaceAll("\n", "<br/>")}}></span></span>)
      else return (<span title={initialValue} >{initialValue}</span>)
    }
  }
  dateCell(initialValue, id , row, edit = true, time = false, clear = false) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    const clearDate = value => { this.handleClearDate(row._index, id); document.getElementById(id).value=''}
    if (edit && this.state.selected === row._index) {
      if (!initialValue) initialValue = new Date().getFullYear() + "-01-01"
      return (
        <span><TextField id={id} type="date" value={initialValue} InputLabelProps={{ shrink: true,}} onChange={inputChange} className="datefield"/>
        {clear ? <img src={imgTrash} onClick={clearDate} alt="X" /> : null}
        </span>
      )
    }
    else {
      if (!initialValue) return null
      if (time) return (<span title={dateFormat(initialValue, t('dateformat'))}>{dateFormat(initialValue, t('dateformat') + " HH:MM")}</span>)
      else return (<span title={dateFormat(initialValue, t('dateformat'))}>{dateFormat(initialValue, t('dateformat'))}</span>)
    }
  }
  selectCell(id, row, position, options, addempty = false, edit = true, initialvalue = null, mouseoverOptions = null, ratio = false, emptyVal = '') {
    let color1 = null
    const inputChange = value => { this.handleInputChange(index, id, value); }
    const _handleKeyDown = e => { if (e.key === 'Enter') this.handleSubmit(row) }
    const index = row._index
    const default_value = {value: emptyVal, label: t('use_default'), color: '#BBBBBB'}
    if (Array.isArray(options) && options[0].value) {
      //console.log("*", position)
      if (position === -1) color1 = default_value.color;
      Object.entries(options).map((t,k) => { if (t[1].value === position) color1 = t[1].color; return null })
    }
    if (this.state.selected === index && edit === true) {
      //console.log(options)
      return (
      <select name={id} value={position} onChange={inputChange} onKeyDown={_handleKeyDown}  style={{ color: color1, }}>
          {addempty ? <option key="0" value={default_value.value} style={{color: default_value.color}}>{default_value.label}</option> : ""}
          { Array.isArray(options) && options[0].value ? Object.entries(options).map((t,k) => <option key={k} value={t[1].value} style={ t[1].color ? t[1].bgcolor ? { color: t[1].color, backgroundColor: t[1].bgcolor, } : { color: t[1].color, } : t[1].bgcolor ? { backgroundColor: t[1].bgcolor, } : null }>{t[1].label}</option>)
            : Object.entries(options).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) }
        </select>
      )
    }
    else {
      let output, mouseover = ""
      if (Array.isArray(options) && options[0].value) Object.entries(options).map((t,k) => { if (t[1].value === position) output = t[1].label; return null })
      else {
        output = options[position]
        if (mouseoverOptions) mouseover = mouseoverOptions[position]
        else mouseover = output
      }
      if (ratio) {
        if (position === 0) return <div width="100%" align="center"><img src={imgFalse} alt={output} title={mouseover} /></div>
        else if (position === 2) return <div width="100%" align="center"><img src={imgTrash} alt={output} title={mouseover} /></div>
        else return <div width="100%" align="center"><img src={imgTrue} alt={output} title={mouseover} /></div>
      }
      else return (<span title={mouseover}>{initialvalue !== null ? initialvalue : output}</span>)
    }
  }
  selectCellLarge(id, row, value, options, addempty = false, edit = true, initialvalue = null, mouseoverOptions = null, ratio = false, emptyVal = '') {
    //let color1 = null
    //const inputChange = value => { console.log(index, id, value); this.handleInputChange(index, id, value); }
    //const _handleKeyDown = e => { if (e.key === 'Enter') this.handleSubmit(row) }
    let setID = -100
    //let setID1 = document.getElementById(id)
    //if (setID1 !== null) console.log(id, setID1, initialvalue)
    Object.entries(options).map((t,k) => { if (value === t[1]) setID = t[0]; return null})
    for (let key in row) { if (key === id) {setID = row[key]} }
    const handleChange = value => { this.handleDatalistChange(index, id, value, options) }
    const setChange = value => {
      let data = this.state.data
      let listID = -100
      console.log("setChange", index, id, value);
      Object.entries(options).map((t,k) => { if (value.target.value === t[1]) listID = t[0]; return null})
      if (listID === -100) {
        //for (let key in row) { if (key === id) { listID = row[key] } }
      }
      if (listID !== -100) {
        for (let key in row) { if (key === id) { row[key] = listID } }
        data[index] = row
        this.setState({
          data: data,
        })
      }

    }
    const index = row._index
    //const default_value = {value: emptyVal, label: t('use_default'), color: '#BBBBBB'}
    /*if (Array.isArray(options) && options[0].value) {
      //console.log("*", position)
      if (position === -1) color1 = default_value.color;
      Object.entries(options).map((t,k) => { if (t[1].value === position) color1 = t[1].color; return null })
    }*/
    //console.log(value)
    if (this.state.selected === index && edit === true) {
      const listID = id+"_list"
      return (
        <div>
          <input type="text" name={id} id={id} defaultValue={setID} />
          <input list={listID} value={value} className="selectlist" onChange={handleChange} onBlur={setChange} />
          <datalist id={listID}>
            { Array.isArray(options) && options[0].value ? Object.entries(options).map((t,k) => <option key={k} value={t[1].label} style={ t[1].color ? { color: t[1].color, } : null }></option>)
              : Object.entries(options).map((t,k) => <option key={k} value={t[1]}></option>) }
          </datalist>
        </div>
      )
    }
    else {
      let output, mouseover = ""
      if (Array.isArray(options) && options[0].value) Object.entries(options).map((t,k) => { if (t[1].value === value) output = t[1].label; return null })
      else {
        output = value
        if (mouseoverOptions) mouseover = mouseoverOptions[value]
        else mouseover = output
      }
      return (<span title={mouseover}>{initialvalue !== null ? initialvalue : output}</span>)
    }
  }
  checkboxCell(initialValue, id, row, images, choises, edit = true) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    initialValue = parseInt(initialValue)
    console.log(images)
    //console.log(initialValue, id, row, usefalseimg)
    let img1 = null
    let img2 = null
    if (!images[0] === "x") img2 = imgFalse
    if (!images[1] === "ok") img1 = img1 = imgTrue
    if (!images[1] === "up") img1 = img1 = imgUpload
    if (this.state.selected === row._index && edit === true) {
      return (
        <div align="center"><input type="checkbox" name={id} value="1" className="checkbox" checked={initialValue === 1} onChange={inputChange} /></div>
      )
    }
    else {
      if (initialValue === 1) return (<div width="100%" align="center"><img src={img1} alt={choises[1]} title={t(choises[1])} /></div>)
      if (initialValue === 2) return (<div width="100%" align="center"><img src={img1} alt={choises[2]} title={t(choises[2])} /></div>)
      else return (<div width="100%" align="center">{img2 ? <img src={img2} alt={choises[0]} title={choises[0]} /> : "-"}</div>)
    }
  }
  /*selectAdvancedCell(id, row, value, options, addempty = false, edit = true, initialvalue = null) {
    const inputChange = value => { this.handleInputChange(index, id, value) }
    const handleKeyDown = e => { if (e.key === 'Enter') this.handleSubmit(row) }
    const index =row._index
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', position: 'absolute', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, position: 'absolute', }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : undefined,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
      input: (styles) => ({ ...styles }),
      //placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles }),
    }
    let position = 1
    // eslint-disable-next-line array-callback-return
    options.map((t,k) => { if (parseInt(t.value) === value) position = k })
    console.log(value, position, options[position].label)
    if (this.state.selected === index && edit === true) {
    //const colourOptions  = [
    //  { value: '1', label: 'Ocean', color: '#00B8D9', isFixed: true },
    //  { value: '2', label: 'Blue', color: '#0052CC', isDisabled: true },
    //  { value: '3', label: 'Purple', color: '#5243AA' },
    //  { value: '7', label: 'Silver', color: '#666666' },
    //]
    return (
        <div><Select
          //defaultValue={this.state.comparelist[0]}
          options={options}
          styles={colourStyles}
          isSearchable={true}
          className="chartselect"
          onChange={(value) => {inputChange(value)}}
          onKeyDown={(value) => {handleKeyDown(value)}}
          placeholder={t('select')}
        /> </div>
      )
    }
    else return (<span title={initialvalue !== null ? initialvalue : options[position].label}>{initialvalue !== null ? initialvalue : options[position].label}</span>)
  }*/

  edit(row, addButtton = true, delButton = true, editButton = true, updateButton = false, archivButton = false, uploadButton = false, delActive = false) {
    const lineSave = value => { this.handleSubmit(row) }
    const update = value => { this.updateRobot(row.id) }
    const upload = value => { this.upload(row) }
    let saveImg = imgSave
    if (this.state.fieldError) saveImg = imgSaveDisabled

    if (this.state.selected === row._index) {
      if (row.status && (row.status === "4" || row.status === 4)) delButton = false
      return (
        <span>
          <img src={saveImg} alt={t("save")} title={t("save")} onClick={lineSave} />
          {(delButton && row.id !== -1) || delActive ? <img src={imgTrash} alt={t("delete")} title={t("delete")} onClick={() => { this.setState({confirmVisible: true, title: t("delete_line"), choise: true, }) } }/> : null}
          {archivButton ? <img src={imgArchiv} alt={t("archive")} title={t("archive")} onClick={() => { this.setState({confirmVisible: true, title: t("archiv_line"), choise: true, }) } }/> : null }
          {uploadButton ? <img src={imgUpload} alt={t("feed_upload")} title={t("feed_upload")} onClick={upload}/> : null}
          <img src={imgCancel} alt={t("cancel")} title={t("cancel")} onClick={() => { this.setState({selected: null, showBase: false}); this.reload() } }/>
        </span>
      )
    }
    else {
      if (!(this.state.data.length-1 === row._index && (row.id > 0 || row.id !== ""))) addButtton=false
      return (
        <span>
          {editButton ? <img src={imgEdit} alt={t("edit")} title={t("edit")} onClick={() => { this.setState({ selected: row._index, showHideEdit: false, }) } }/> : null }
          {updateButton ? <span><img src={imgInstall} alt={t("send_to_robot")} title={t("send_to_robot")} onClick={update}/></span> : null}
          {addButtton ? <img src={imgAdd} alt={t("addline")} title={t("addline")} onClick={this.insertRow}/> : null}
        </span>
      )
    }
  }

}

export default TableFunctions
